/* eslint-disable react/jsx-props-no-spreading */
import { FaAsterisk, FaMoneyBillWave, FaUser } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { ReactComponent as AiIcon } from "../../assets/ai.svg";
import { ManageScroll } from "../../components/ManageScroll";
import { withAuth } from "../../contexts/auth.context";
import { useChatsAdmin } from "../../services/chat-services";
import { usePasswordResetRequests } from "../../services/password-reset-services";
import { useInvoices, useSubscriptions } from "../../services/subscription-services";
import { useUsers } from "../../services/user-services";
import AppPageLayout from "../DocumentPage/app-page-layout";
import ChatsSearchResults from "./chats-search-results";
import FiltersSidebar from "./filters-sidebar";
import InvoiceSearchResults from "./invoice-search-results";
import PasswordResetRequestSearchResults from "./password-reset-request-search-results";
import ResultsNav from "./results-nav";
import SubscriptionSearchResults from "./subscription-search-results";
import UserSearchResults from "./user-search-results";

const AdminPage = () => {
  const intl = useIntl();

  const { category } = useParams();

  return (
    <AppPageLayout
      navigation={<FiltersSidebar />}
      title={intl.formatMessage({ id: "admin-page.title" })}
      className="search-page"
    >
      <ManageScroll />
      <SearchResultsNavBar />

      {category === "users" && <UserSearchResults />}
      {category === "subscriptions" && <SubscriptionSearchResults />}
      {category === "invoices" && <InvoiceSearchResults />}
      {category === "password-reset-requests" && <PasswordResetRequestSearchResults />}
      {category === "chats" && <ChatsSearchResults />}
    </AppPageLayout>
  );
};

const SearchResultsNavBar = () => {
  const { category } = useParams();

  const users = useUsers();
  const subscriptions = useSubscriptions();
  const invoices = useInvoices();
  const passwordResetRequests = usePasswordResetRequests();
  const chats = useChatsAdmin();

  return (
    <ResultsNav
      activeCategory={category || "users"}
      loading={!users}
      items={[
        {
          category: "users",
          label: "Потребители",
          count: users.totalCount,
          icon: <FaUser />,
        },
        {
          category: "subscriptions",
          label: "Абонаменти",
          count: subscriptions.totalCount,
          icon: <FaMoneyBillWave />,
        },
        {
          category: "invoices",
          label: "Фактури",
          count: invoices.totalCount,
          icon: <FaMoneyBillWave />,
        },
        {
          category: "password-reset-requests",
          label: "Забравени пароли",
          count: passwordResetRequests.totalCount,
          icon: <FaAsterisk />,
        },
        {
          category: "chats",
          label: "Попитай Лексебра",
          count: chats.totalCount,
          icon: <AiIcon height="1rem" width="1rem" />,
        },
      ]}
    />
  );
};

export default withAuth(AdminPage);
