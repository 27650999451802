import { EntityModel } from "hateoas-hal-types";
import { useEffect, useMemo, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { FaExpand, FaExternalLinkAlt } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { v4 as uuid } from "uuid";
import { mutate } from "../../api";
import Skeleton from "../../components/Skeleton";
import { useProtectedAction } from "../../contexts/auth.context";
import { Chat, ChatInitiationContext, isInProgress } from "../../models/Chat.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { useChat } from "../../services/chat-services";
import DocumentAction from "../DocumentPage/document-action";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentQuickViewLayout from "../DocumentPage/document-quick-view-layout";
import "./chat-quick-view-page.scss";
import { ChatViewPageForm } from "./chat-view-page";
import "./chat-view-page.scss";
import SearchInputLexebraGPT from "./gpt-input";

interface ChatQuickViewPageProps {
  query: string;
  onClose: () => void;
}

const ChatQuickViewPage: React.FC<ChatQuickViewPageProps> = ({ query, onClose }) => {
  return <ChatViewForm query={query} onClose={onClose} />;
};

interface ChatViewPageProps {
  query: string;
  onClose: () => void;
}

const ChatViewForm: React.FC<ChatViewPageProps> = ({ query, onClose }) => {
  const id = useMemo(() => uuid(), []);
  const [submitting, setSubmitting] = useState(false);
  const [chatId, setChatId] = useState<string | undefined>(
    query.startsWith("id:") ? query.split(":")[1] : undefined
  );
  const chat = useChat(chatId);

  const anyResponseInProgress = chat?.responses.findIndex((r) => isInProgress(r.progress)) !== -1;

  useEffect(() => {
    if (anyResponseInProgress) {
      const interval = setInterval(() => {
        mutate(`/chats/${chat?.id}`, (current: Chat) => current, true);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [anyResponseInProgress, chat?.id]);

  return (
    <DocumentQuickViewLayout
      id={id}
      title={chat?.title || "Попитай Лексебра"}
      className="chat-page"
      menu={
        chat?.responses.length ? (
          <ChatQuickViewPageActions chat={chat} onClose={onClose} />
        ) : (
          <ChatQuickViewPageActionsNoChat onClose={onClose} />
        )
      }
      onClose={onClose}
    >
      <div className="w-100">
        {!chat && !query.startsWith("id:") && (
          <SearchInputLexebraGPT
            query={query}
            mode={"view"}
            setMode={() => {}}
            submitting={submitting}
            setSubmitting={setSubmitting}
            onCreate={(chat) => setChatId(chat.id)}
            initiationContext={ChatInitiationContext.ACT_TEXT_SELECTION}
          />
        )}
        {chat && <ChatViewPageForm chat={chat} quickView />}
      </div>
    </DocumentQuickViewLayout>
  );
};

interface ChatQuickViewPageActionsProps {
  chat: EntityModel<Chat>;
  onClose?: () => void;
}

const ChatQuickViewPageActions: React.FC<ChatQuickViewPageActionsProps> = ({ chat, onClose }) => {
  const navigate = useNavigate();
  const protectedAction = useProtectedAction(chat.accessMode === DocumentAccessMode.FREE);
  const expandChatNewTab = protectedAction(() => window.open(`/ask-lexebra/${chat?.id}`, "_blank"));

  const expandChat = protectedAction(() => {
    navigate(`/ask-lexebra/${chat.id}`);
    onClose && onClose();
  });
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const intl = useIntl();
  return (
    <DocumentActionsContainer
      document={{
        id: chat.id,
        title: chat.title,
      }}
      quickview
    >
      <DocumentAction
        handler={expandChat}
        icon={<FaExpand />}
        label={intl.formatMessage({ id: "judgement-act-actions.open-full-screen" })}
      />
      {!isMobileOnly && (
        <DocumentAction
          handler={expandChatNewTab}
          icon={<FaExternalLinkAlt />}
          label={intl.formatMessage({ id: "judgement-act-actions.open-new-tab" })}
        />
      )}
      <DocumentCloseAction handler={onClose} />
    </DocumentActionsContainer>
  );
};

const ChatQuickViewPageActionsNoChat: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <DocumentActionsContainer quickview>
      <DocumentCloseAction handler={onClose} />
    </DocumentActionsContainer>
  );
};

const ChatQuickViewPageLoading: React.FC<{ onClose: () => void }> = (props) => {
  const id = useMemo(() => uuid(), []);
  return (
    <DocumentQuickViewLayout onClose={props.onClose} id={id}>
      <div className="w-100">
        <Skeleton count={10} />
      </div>
    </DocumentQuickViewLayout>
  );
};

export default ChatQuickViewPage;
