import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import AutoSizedTextArea from "../../components/AutoSizeTextArea";
import NotAuthorized from "../../components/NotAuthorized";
import SelectMultiple from "../../components/SimpleSelect/select-multiple";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import { User } from "../../models/User.model";
import { useDocumentSets } from "../../services/ref-data-services";
import { useRoles, useUser } from "../../services/user-services";
import EditPageLayout from "../DocumentPage/edit-page-layout";
import DevicesTable from "./devices.table";
import SubscriptionsTable from "./subscriptions.table";
import UserEditPageActions from "./user-edit-page-actions";
import UserPageLoading from "./user-page-loading";
interface UserEditPageProps {
  user?: EntityModel<User>;
}

const UserEditPage = () => {
  const { userId } = useParams();
  const user = useUser(userId);

  return user || !userId ? <UserEditForm user={user} /> : <UserPageLoading />;
};

const UserEditForm: React.FC<UserEditPageProps> = ({ user }) => {
  const serverData = user;
  const intl = useIntl();

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: serverData || {
      enabled: false,
      internal: false,
      devices: [],
      subscriptions: [],
      actsExportQuota: 100,
      summariesExportQuota: 30,
      devicesQuota: 3,
      availableGptCredits: 20,
      refillGptCredits: 20,
      refillGptCreditsDate: dayjs().format("YYYY-MM-DD"),
    },
  });

  const { control, register } = methods;

  const [changePass, setChangePass] = useState(!user);

  const roles = useRoles();
  const { data: documentSets } = useDocumentSets();

  return (
    <FormProvider {...methods}>
      <EditPageLayout
        menu={<UserEditPageActions />}
        title={
          user
            ? `${user.firstName} ${user.lastName}(${user.email})`
            : intl.formatMessage({ id: "user-edit.page.add-new-title" })
        }
        className="user-page"
      >
        <Form className="edit-form" autoComplete="off">
          <Row>
            <Col md="6">
              <Form.Group controlId="email">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.email" />
                </Form.Label>
                <Form.Control type="text" {...register("email")} autoFocus />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="role">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.roles" />
                </Form.Label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={roles}
                      getOption={(role) => ({
                        label: role.name,
                        value: role.id,
                      })}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="internal">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.internal" />
                </Form.Label>
                <Controller
                  name="internal"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={["true", "false"]}
                      getOption={(option) => ({
                        label: intl.formatMessage({ id: `boolean.${option}` }),
                        value: option,
                      })}
                      value={value + ""}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="enabled">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.enabled" />
                </Form.Label>
                <Controller
                  name="enabled"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={["true", "false"]}
                      getOption={(option) => ({
                        label: intl.formatMessage({ id: `boolean.${option}` }),
                        value: option,
                      })}
                      value={value + ""}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Row>
                <Col md="12">
                  <Form.Group controlId="firstName">
                    <Form.Label>
                      <FormattedMessage id="edit-user.form.firstName" />
                    </Form.Label>
                    <Form.Control type="text" {...register("firstName")} />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group controlId="lastName">
                    <Form.Label>
                      <FormattedMessage id="edit-user.form.lastName" />
                    </Form.Label>
                    <Form.Control type="text" {...register("lastName")} />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Form.Group controlId="role">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.notes" />
                </Form.Label>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} minHeight={95} />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group controlId="documentSets">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.documentSets" />
                </Form.Label>
                <Controller
                  name="documentSets"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectMultiple
                      options={documentSets}
                      getOption={(documentSet) => ({
                        label: documentSet.shortName,
                        value: documentSet.id,
                      })}
                      value={value}
                      onChange={onChange}
                      placeholder="Изберете..."
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group controlId="actsExportQuota">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.actsExportQuota" />
                </Form.Label>
                <Form.Control type="text" {...register("actsExportQuota")} />
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group controlId="summariesExportQuota">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.summariesExportQuota" />
                </Form.Label>
                <Form.Control type="text" {...register("summariesExportQuota")} />
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group controlId="devicesQuota">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.devicesQuota" />
                </Form.Label>
                <Form.Control type="text" {...register("devicesQuota")} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6"></Col>
            <Col md="2">
              <Form.Group controlId="availableGptCredits">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.availableGptCredits" />
                </Form.Label>
                <Form.Control type="text" {...register("availableGptCredits")} />
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group controlId="refillGptCredits">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.refillGptCredits" />
                </Form.Label>
                <Form.Control type="text" {...register("refillGptCredits")} />
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group controlId="refillGptCreditsDate">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.refillGptCreditsDate" />
                </Form.Label>
                <Controller
                  name="refillGptCreditsDate"
                  control={control}
                  render={({ field: { value } }) => (
                    <Form.Control
                      type="text"
                      readOnly={true}
                      value={intl.formatDate(dayjs(value, "YYYY-MM-DD").toDate(), {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row></Row>
          {user && (
            <Row>
              <Col md="6">
                <Form.Check
                  type="checkbox"
                  label={intl.formatMessage({ id: "edit-user.form.changePass" })}
                  onChange={() => setChangePass(!changePass)}
                  checked={changePass}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md="6">
              <Form.Group controlId="password">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.password" />
                </Form.Label>
                <Form.Control type="password" {...register("password")} disabled={!changePass} />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="passwordConfirm">
                <Form.Label>
                  <FormattedMessage id="edit-user.form.passwordConfirm" />
                </Form.Label>
                <Form.Control
                  type="password"
                  {...register("passwordConfirm")}
                  disabled={!changePass}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Controller
                name="subscriptions"
                control={control}
                render={({ field: { onChange, value } }) => <SubscriptionsTable value={value} />}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Controller
                name="devices"
                control={control}
                render={({ field: { onChange, value } }) => <DevicesTable value={value} />}
              />
            </Col>
          </Row>
        </Form>
      </EditPageLayout>
    </FormProvider>
  );
};

const ProtectedUserEditPage = () => {
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_USERS)} fallback={NotAuthorized}>
      <UserEditPage />
    </Visible>
  );
};

export default ProtectedUserEditPage;
