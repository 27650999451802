import { EntityModel } from "hateoas-hal-types";
import { FunctionComponent } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";
import { hasPrivilege, hasUsername, oneOf, Privileges, Visible } from "../../contexts/auth.context";
import { Chat } from "../../models/Chat.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { updateChatStatus } from "../../services/chat-services";
import DocumentAction from "../DocumentPage/document-action";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentCollectionActions from "../DocumentPage/document-collection-actions";
import DocumentEditAction from "../DocumentPage/document-edit-action";
import DocumentReviewAction from "../DocumentPage/document-review-action";
import DocumentShareAction from "../DocumentPage/document-share-action";
import DocumentStatusAction from "../DocumentPage/document-status-action";
import { ChatViewPageMode } from "./chat-view-page";

interface ChatViewPageActionsProps {
  chat?: EntityModel<Chat>;
  setMode: (mode: ChatViewPageMode) => void;
  mode: ChatViewPageMode;
}

const ChatViewPageActions: FunctionComponent<ChatViewPageActionsProps> = ({
  chat,
  setMode,
  mode,
}) => {
  const navigate = useNavigate();
  const { cache } = useSWRConfig();
  const deleteChat = async (chatId: string, status: DocumentStatus) => {
    try {
      await updateChatStatus(chatId, status, cache);
      toast.success("Въпросът беше изтрит успешно");
    } catch (error) {
      console.error(error);
      toast.error("Възникна грешка при изтриването на въпроса");
    }
  };
  const { state } = useLocation();
  return mode === "view" ? (
    <DocumentActionsContainer document={chat}>
      {chat && (
        <>
          <Visible
            when={oneOf(hasUsername(chat.user.email), hasPrivilege(Privileges.EDIT_SUMMARIES))}
          >
            <DocumentEditAction handler={() => setMode("edit")} />
          </Visible>
          <DocumentShareAction
            title={chat.query}
            link={`/ask-lexebra/${chat.id}`}
            id={chat.id}
            desc={chat.title}
          />
          {chat.user.degree && (
            <>
              <DocumentReviewAction
                document={chat}
                setStatus={(status) => updateChatStatus(chat.id, status)}
              />
              <DocumentStatusAction
                showOnReview
                document={chat}
                setStatus={(status) => updateChatStatus(chat.id, status)}
              />
            </>
          )}
          {chat.status !== DocumentStatus.DELETED && (
            <Visible
              when={oneOf(
                hasUsername(chat.user?.email),
                hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)
              )}
            >
              <DocumentAction
                handler={() =>
                  deleteChat(chat.id, DocumentStatus.DELETED).then(() =>
                    navigate(state && state.from ? state.from : "/ask-lexebra")
                  )
                }
                icon={<FaRegTrashAlt />}
                label="Изтрий"
              />
            </Visible>
          )}
          <DocumentCollectionActions accessMode={DocumentAccessMode.PAID} />
        </>
      )}
      <DocumentCloseAction
        handler={() => navigate(state && state.from ? state.from : "/ask-lexebra")}
      />
    </DocumentActionsContainer>
  ) : null;
};

export default ChatViewPageActions;
