/* eslint-disable react/jsx-props-no-spreading */
import dayjs from "dayjs";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { RiSpyFill } from "react-icons/ri";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { InlineLinkButton } from "../../components/Button/button";
import LabelValue from "../../components/label-value";
import PreloadingLink from "../../components/PreloadingLink";
import { useAuth, withAuth } from "../../contexts/auth.context";
import { Selection } from "../../hooks/useMultipleSelection";
import { User } from "../../models/User.model";
import { runAs } from "../../services/auth.service";
import { prefetchSubscription } from "../../services/subscription-services";
import { prefetchUser, userHref, useUserActivity, useUsers } from "../../services/user-services";
import ResultsTable from "./ResultsTable/results-table";
import ResultsTableHeader from "./ResultsTable/results-table-header";

const UserSearchResults = () => {
  const { formatMessage } = useIntl();

  const results = useUsers();

  const { setCurrentUser } = useAuth();

  return (
    <ResultsTable
      results={results}
      header={() => (
        <ResultsTableHeader
          addNewPath="/admin/users/new"
          sortOptions={[
            {
              value: "id",
              label: "Дата на създаване",
            },
            {
              value: "username",
              label: "Потребителско име",
            },
          ]}
        />
      )}
      itemToSelection={itemToSelection}
      allowBulkLoad
      row={(item) => (
        <ResultsTable.ResultTableRow>
          <PreloadingLink
            to={`/admin/users/${item.id}`}
            state={{ title: item.firstName + " " + item.lastName }}
            preload={(cache) => prefetchUser(item.id, cache)}
          >
            {item.email}
          </PreloadingLink>
          <InlineLinkButton
            onClick={async () => {
              try {
                setCurrentUser(await runAs(item.email));
                toast.info(formatMessage({ id: "user.menu.toasts.run-as.success" }));

                window.location.href = "/";
              } catch (e) {
                console.log(e);
                toast.error(formatMessage({ id: "user.menu.toasts.run-as.error" }));
              }
            }}
            title={formatMessage({ id: "user-search-page.labels.impersonate" })}
          >
            <RiSpyFill />
          </InlineLinkButton>
          <LabelValue label={formatMessage({ id: "user-search-page.labels.name" })}>
            {item.firstName + " " + item.lastName}
          </LabelValue>
          <LabelValue label={formatMessage({ id: "user-search-page.labels.role" })}>
            {item.role.name}
          </LabelValue>
          <LabelValue label={formatMessage({ id: "user-search-page.labels.current-subscription" })}>
            {item.currentSubscription ? (
              <>
                <PreloadingLink
                  to={`/admin/subscriptions/${item.currentSubscription.id}`}
                  state={{ title: item.currentSubscription.code }}
                  preload={(cache) => prefetchSubscription(item.currentSubscription!.id, cache)}
                >
                  {item.currentSubscription.code}
                </PreloadingLink>{" "}
                ({item.currentSubscription.plan.type.name})
              </>
            ) : (
              "-"
            )}
          </LabelValue>
          {item.currentSubscription && (
            <LabelValue
              label={formatMessage({
                id: "user-search-page.labels.current-subscription-expires-on",
              })}
            >
              {item.currentSubscription.expiresOn ? (
                <FormattedDate
                  value={item.currentSubscription.expiresOn}
                  month="long"
                  year="numeric"
                  day="numeric"
                />
              ) : (
                <FormattedMessage id="user-search-page.labels.no-expiration" />
              )}
            </LabelValue>
          )}
          <LabelValue
            label={formatMessage({
              id: "user-search-page.labels.activity",
            })}
          >
            <UserActivityBadge user={item} />
          </LabelValue>
          <ResultsTable.RowIndicators item={item}>
            {item.internal && (
              <Badge bg="warning" className="me-1">
                {formatMessage({ id: `user-search-page.labels.internal` })}
              </Badge>
            )}
            <Badge bg={item.enabled ? "success" : "secondary"}>
              {formatMessage({ id: `user-search-page.labels.enabled.${item.enabled}` })}
            </Badge>
          </ResultsTable.RowIndicators>
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

const UserActivityBadge: React.FC<{ user: User }> = ({ user }) => {
  const stats = useUserActivity(user.id);
  const date1 = dayjs(new Date());
  const diffInDays = stats?.lastActivity ? date1.diff(stats?.lastActivity, "day") : null;
  return stats && diffInDays !== null ? (
    <span>
      <OverlayTrigger
        placement="top"
        trigger="click"
        overlay={(props) => (
          <Tooltip id="user-stats-tooltip" {...props}>
            <div style={{ whiteSpace: "nowrap" }}>
              <table cellSpacing={2}>
                <thead>
                  <th></th>
                  <th>Резюмета</th>
                  <th>Актове</th>
                  <th>Новини</th>
                  <th>Бюлетини</th>
                  <th>Oбщо</th>
                </thead>
                <tbody>
                  <tr>
                    <td>Днес</td>
                    <td>
                      {stats.opened.summaries.TODAY || 0}/{stats.exported.summaries.TODAY || 0}
                    </td>
                    <td>
                      {stats.opened.acts.TODAY || 0}/{stats.exported.acts.TODAY || 0}
                    </td>
                    <td>{stats.opened.articles.TODAY || 0}/0</td>
                    <td>
                      {stats.opened.bulletins.TODAY || 0}/{stats.exported.bulletins.TODAY || 0}
                    </td>
                    <td>
                      {stats.opened.totals.TODAY || 0}/{stats.exported.totals.TODAY || 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Вчера</td>
                    <td>
                      {stats.opened.summaries.YESTERDAY || 0}/
                      {stats.exported.summaries.YESTERDAY || 0}
                    </td>
                    <td>
                      {stats.opened.acts.YESTERDAY || 0}/{stats.exported.acts.YESTERDAY || 0}
                    </td>
                    <td>{stats.opened.articles.YESTERDAY || 0}/0</td>
                    <td>
                      {stats.opened.bulletins.YESTERDAY || 0}/
                      {stats.exported.bulletins.YESTERDAY || 0}
                    </td>
                    <td>
                      {stats.opened.totals.YESTERDAY || 0}/{stats.exported.totals.YESTERDAY || 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Последната седмица</td>
                    <td>
                      {stats.opened.summaries.LAST_WEEK || 0}/
                      {stats.exported.summaries.LAST_WEEK || 0}
                    </td>
                    <td>
                      {stats.opened.acts.LAST_WEEK || 0}/{stats.exported.acts.LAST_WEEK || 0}
                    </td>
                    <td>{stats.opened.articles.LAST_WEEK || 0}/0</td>
                    <td>
                      {stats.opened.bulletins.LAST_WEEK || 0}/
                      {stats.exported.bulletins.LAST_WEEK || 0}
                    </td>
                    <td>
                      {stats.opened.totals.LAST_WEEK || 0}/{stats.exported.totals.LAST_WEEK || 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Последния месец</td>
                    <td>
                      {stats.opened.summaries.LAST_MONTH || 0}/
                      {stats.exported.summaries.LAST_MONTH || 0}
                    </td>
                    <td>
                      {stats.opened.acts.LAST_MONTH || 0}/{stats.exported.acts.LAST_MONTH || 0}
                    </td>
                    <td>{stats.opened.articles.LAST_MONTH || 0}/0</td>
                    <td>
                      {stats.opened.bulletins.LAST_MONTH || 0}/
                      {stats.exported.bulletins.LAST_MONTH || 0}
                    </td>
                    <td>
                      {stats.opened.totals.LAST_MONTH || 0}/{stats.exported.totals.LAST_MONTH || 0}
                    </td>
                  </tr>
                  <tr>
                    <td>От началото</td>
                    <td>
                      {stats.opened.summaries.ALL || 0}/{stats.exported.summaries.ALL || 0}
                    </td>
                    <td>
                      {stats.opened.acts.ALL || 0}/{stats.exported.acts.ALL || 0}
                    </td>
                    <td>{stats.opened.articles.ALL || 0}/0</td>
                    <td>
                      {stats.opened.bulletins.ALL || 0}/{stats.exported.bulletins.ALL || 0}
                    </td>
                    <td>
                      {stats.opened.totals.ALL || 0}/{stats.exported.totals.ALL || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tooltip>
        )}
      >
        <span style={{ textDecoration: "underline", cursor: "pointer" }}>
          <FormattedDate value={stats?.lastActivity} month="long" year="numeric" day="numeric" />
        </span>
      </OverlayTrigger>
      ({diffInDays ? (diffInDays === 1 ? "вчера" : `преди ${diffInDays} дена`) : "днес"})
      <FaCircle
        className={
          "ms-1 " +
          (diffInDays <= 3 ? "text-success" : diffInDays <= 7 ? "text-warning" : "text-danger")
        }
      />
    </span>
  ) : (
    <>-</>
  );
};

const itemToSelection = (item: User): Selection => ({
  _id: item.id,
  title: item.email,
  href: userHref(item.id),
});

export default withAuth(UserSearchResults);
