import { useEffect, useMemo, useRef } from "react";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { IoMdFunnel, IoMdRedo, IoMdUndo } from "react-icons/io";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./rich-text-editor.scss";

var Parchment = ReactQuill.Quill.import("parchment");
let bulletinPart = new Parchment.Attributor.Class("bp", "bp", {
  scope: Parchment.Scope.BLOCK,
});
ReactQuill.Quill.register(bulletinPart, true);

const RichTextEditor: React.FC<any> = (props) => {
  const ref = useRef<ReactQuill>(null);

  const valueProp =
    props.defaultValue !== undefined
      ? {
          defaultValue:
            (props.plainText
              ? props.defaultValue?.replace(/(?:\r\n|\r|\n)/g, "<br>")
              : props.defaultValue) || "",
        }
      : {
          value:
            (props.plainText ? props.value?.replace(/(?:\r\n|\r|\n)/g, "<br>") : props.value) || "",
        };

  const { onChange, plainText, singleLine, defaultValue } = props;
  useEffect(() => {
    if (defaultValue !== undefined) {
      const quill = ref.current;
      const handler = () => {
        onChange(
          plainText
            ? singleLine
              ? quill?.editor?.getText().replaceAll(/\r?\n|\r/g, "")
              : quill?.editor?.getText()
            : quill?.editor?.root.innerHTML
        );
      };
      quill?.editor?.on("text-change", handler);
      return () => {
        quill?.editor?.off("text-change", handler);
      };
    }
  }, [onChange, plainText, singleLine, defaultValue]);
  return (
    <div
      className={
        props.expandHeight
          ? "rte-expandable not-expanded"
          : props.headless
          ? " rte-headless"
          : props.singleLine
          ? " rte-single-line"
          : ""
      }
    >
      <CustomToolbar id={props.id} customButtons={props.buttons} />
      <ReactQuill
        id={`editor-${props.id}`}
        theme="snow"
        ref={ref}
        placeholder={props.placeholder}
        {...valueProp}
        onChange={(content, delta, source, editor) => {
          if (defaultValue === undefined) {
            props.onChange(
              props.plainText
                ? props.singleLine
                  ? editor.getText().replaceAll(/\r?\n|\r/g, "")
                  : editor.getText()
                : editor.getHTML()
            );
          }
        }}
        style={{ height: props.height, display: "inline-block", width: "100%" }}
        formats={
          props.plainText
            ? []
            : [
                "header",
                "bold",
                "italic",
                "blockquote",
                "list",
                "indent",
                "link",
                "summary-title",
                "summary-question",
                "act-title",
                "chat-title",
                "preset",
                "bp",
              ]
        }
        onBlur={() => {
          if (props.expandHeight && ref.current) {
            ref.current.getEditor().root.parentElement!.parentElement!.style.height = props.height;
          }
        }}
        onFocus={() => {
          if (props.expandHeight && ref.current) {
            const quillEl = ref.current.getEditor().root.parentElement!.parentElement!;
            quillEl.parentElement!.className = quillEl.parentElement!.className.replaceAll(
              " not-expanded",
              " expanded"
            );
            quillEl.style.height = props.expandHeight;
          }
        }}
        modules={useMemo(
          () => ({
            history: {
              userOnly: true,
            },
            clipboard: {
              singleLine: props.singleLine,
            },
            keyboard: {
              bindings: {
                "list autofill": {
                  prefix: /^__()$/,
                },

                linebreak: {
                  key: 13,
                  shiftKey: true,
                  handler: function (range: any) {
                    const quill = (this as any).quill;
                    quill.removeFormat(range.index, 0, "user");
                    return true;
                  },
                },

                // handleBackspace: {
                //   key: "Backspace",
                //   format: ["preset"],
                //   offset: 0,
                //   collapsed: true,
                //   handler: function (range: any, context: any) {
                //     const quill = (this as any).quill;

                //     if (context.format.preset) {
                //       quill.format("preset", false);
                //       quill.setSelection(range.index - 1, 0, "user");
                //     }
                //     return true;
                //   },
                // },

                handleEnter: props.singleLine
                  ? {
                      key: 13,
                      handler: () => {},
                    }
                  : {
                      key: 13,
                      shiftKey: false,
                      handler: function (range: any) {
                        const quill = (this as any).quill;
                        const currentLeaf = quill.getLeaf(range.index)[0];
                        if (currentLeaf.text === "Теза:") {
                          quill.format("preset", "thesis", "api");
                          quill.deleteText(range.index - 5, 5, "api");
                          return false;
                        }
                        if (currentLeaf.text === "---") {
                          quill.format("preset", "notes", "api");
                          quill.deleteText(range.index - 3, 3, "api");
                          return false;
                        }
                        if (currentLeaf.text === "Бележка:") {
                          quill.format("preset", "comments", "api");
                        }

                        return true;
                      },
                    },
              },
            },
            toolbar: {
              container: "#toolbar-" + props.id,
              handlers: {
                ...Object.keys(props.handlers || {}).reduce(
                  (acc: any, key: string) => ({
                    ...acc,
                    [key]: () => props.handlers[key](() => ref.current),
                  }),
                  {}
                ),

                undo: () => {
                  (ref.current?.getEditor() as any).history.undo();
                },
                redo: () => {
                  (ref.current?.getEditor() as any).history.redo();
                },

                format: () => {
                  const quill = ref.current?.getEditor();
                  if (quill) {
                    const text = quill.getText();
                    const abbreviation_exceptions = ["б.", " наб.", " г.", "напр.", " сл.", "обн."];

                    //const lines = text.split(
                    //  /(?<=[^\d\/\s.(]{5,})(?<!\()(?<!\d\.)(?<!\.\S)\.(?=\s|$)/
                    //);//safe version

                    const lines = text
                      .replace(
                        /\. (?!(Решение|Определение|Тълкувателно|Постановление|Разпореждане)\b)(?=([A-ZА-Я]|"|„))/g,
                        ".\n"
                      )
                      .split(/(?<=[^\d\/\s.(]{5,})(?<!\()(?<!\d\.)(?<!\.\S)\.\s(?=[A-Z]\w*\s|$)/);
                    let formattedText = lines.join(".\n");

                    abbreviation_exceptions.forEach((abbr) => {
                      formattedText = formattedText.replace(
                        new RegExp(`${abbr}\n`, "g"),
                        abbr.trim() + " "
                      );
                    });

                    formattedText = formattedText.replace(/([A-ZА-Я]\.)\n([A-ZА-Я]\.)/g, "$1 $2");

                    quill.setText(formattedText, "user");
                  }
                },

                collapse: () => {
                  if (props.expandHeight && ref.current) {
                    const quillEl = ref.current.getEditor().root.parentElement!.parentElement!;

                    quillEl.style.height =
                      Math.max(
                        parseInt(props.height.replace("px", "")),
                        parseInt(quillEl.style.height.replace("px", "")) - 250
                      ) + "px";
                  }
                },
                expand: () => {
                  if (props.expandHeight && ref.current) {
                    const quillEl = ref.current.getEditor().root.parentElement!.parentElement!;

                    quillEl.style.height =
                      parseInt(quillEl.style.height.replace("px", "")) + 250 + "px";
                  }
                },
              },
            },
          }),
          [props.handlers, props.id, props.expandHeight, props.height, props.singleLine]
        )}
      />
    </div>
  );
};

const CustomToolbar: React.FC<{ id: string; customButtons: any }> = ({ id, customButtons }) => (
  <div id={`toolbar-${id}`}>
    <select className="ql-header">
      <option value="2">Heading 2</option>
      <option value="3">Heading 3</option>
      <option value="4">Heading 4</option>
      <option value="5">Heading 5</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-blockquote"></button>
    <button className="ql-link" value="+1"></button>
    {customButtons}
    <button className="ql-format" title="Форматирай по точките">
      <IoMdFunnel />
    </button>
    <button className="ql-undo" title="Отмени последната промяна">
      <IoMdUndo />
    </button>
    <button className="ql-redo" title="Приложи последната промяна">
      <IoMdRedo />
    </button>
    <button className="ql-collapse" title="Намали размера">
      <FaAngleDoubleUp />
    </button>
    <button className="ql-expand" title="Увеличи размера">
      <FaAngleDoubleDown />
    </button>
  </div>
);

const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

class PlainClipboard extends Clipboard {
  onPaste(e: any) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData("text/plain") as string;

    const format = this.quill.getFormat(range.index, 0);
    if (this.quill.getFormat(range.index, 0).preset) {
      const delta = new Delta().retain(range.index).delete(range.length).insert(text, format);
      this.quill.updateContents(delta, "user");
      // range.length contributes to delta.length()
      this.quill.setSelection(range.index + text.replaceAll(/\r/g, "").length, "silent");
    } else {
      const delta = new Delta().retain(range.index).delete(range.length);
      this.quill.updateContents(delta, "user");
      this.quill.clipboard.dangerouslyPasteHTML(
        range.index,
        plainTextToHtml(this.options.singleLine ? text.replaceAll(/\r?\n|\r/g, "") : text),
        "user"
      );
    }

    this.quill.scrollIntoView();
  }
}

Quill.register(
  {
    "modules/clipboard": PlainClipboard,
  },
  true
);

const plainTextToHtml = (text: string) => {
  text = text
    // Encode <>.
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    // Creates a paragraph for each double line break.
    .replace(/\r?\n\r?\n/g, "</p><p>")
    // Creates a line break for each single line break.
    .replace(/\r?\n/g, "<br>")
    // Preserve trailing spaces (only the first and last one – the rest is handled below).
    .replace(/^\s/, "&nbsp;")
    .replace(/\s$/, "&nbsp;")
    // Preserve other subsequent spaces now.
    .replace(/\s\s/g, " &nbsp;");

  if (text.includes("</p><p>") || text.includes("<br>")) {
    // If we created paragraphs above, add the trailing ones.
    text = `<p>${text}</p>`;
  }

  return text;
};

export default RichTextEditor;
