import DocumentAccessMode from "./DocumentAccessMode.enum";
import DocumentStatus from "./DocumentStatus.enum";
import { JudgementAct } from "./JudgementAct.model";
import Provision from "./Provision.model";
import RefData from "./RefData.model";
import SummaryQa from "./SummaryQa.model";

export interface Chat {
  user: {
    id: number;
    email: string;
    name: string;
    degree: string;
  };
  id: string;
  title: string;
  query: string;
  saved: boolean;
  status: DocumentStatus;
  accessMode: DocumentAccessMode;
  cost: number;
  createdOn: string;
  timeReading: number;
  publishDate: string;
  manuallyEdited: boolean;

  responses: ChatResponse[];
}

export enum ChatResponseProgress {
  GENERATING = "GENERATING",
  RECEIVING = "RECEIVING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  ERROR_RETRYABLE = "ERROR_RETRYABLE",
  TIMEOUT = "TIMEOUT",
}

export enum ChatInitiationContext {
  USER_QUESTION = "USER_QUESTION",
  ACT_QUESTION = "ACT_QUESTION",
  ACT_TEXT_SELECTION = "ACT_TEXT_SELECTION",
  ACT_ANNOTATIONS = "ACT_ANNOTATIONS",
}

export enum ChatLegalArea {
  CIVIL = "CIVIL",
  CRIMINAL = "CRIMINAL",
  ADMINISTRATIVE = "ADMINISTRATIVE",
}

export const isOnError = (value?: ChatResponseProgress) => {
  return (
    value === ChatResponseProgress.ERROR ||
    value === ChatResponseProgress.ERROR_RETRYABLE ||
    value === ChatResponseProgress.TIMEOUT
  );
};

export const isRetryable = (value?: ChatResponseProgress) => {
  return value === ChatResponseProgress.ERROR_RETRYABLE || value === ChatResponseProgress.TIMEOUT;
};

export const isInProgress = (value: ChatResponseProgress) => {
  return value === ChatResponseProgress.GENERATING || value === ChatResponseProgress.RECEIVING;
};

export interface ChatDocuments {
  id: string;
  questions: { score: number; relatedQuestion: SummaryQa; reference: string }[];
  acts: { score: number; relatedJudgementAct: JudgementAct; reference: string }[];
  provisions: Provision[];
}

export interface ChatResponse {
  content: string;
  model: RefData;
  cost: number;
  createdOn: Date;
  id: string;
  progress: ChatResponseProgress;
  timeElapsedInMs: number;
  comments: string;
}
