/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import dayjs from "dayjs";
import React from "react";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useDeviceSelectors } from "react-device-detect";
import { FaAlignLeft } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import {
  hasPrivilege,
  isLogged,
  not,
  Privileges,
  ProtectedLink,
  useAuth,
  Visible,
} from "../../contexts/auth.context";
import { popperConfig } from "./header";

const StatsMenu = () => {
  const navigate = useNavigate();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const { currentUser } = useAuth();
  if (isMobileOnly) {
    return null; //<UserMenuMobile />;
  }
  if (currentUser && !hasPrivilege(Privileges.VIEW_DOCUMENTS)(currentUser)) {
    return (
      <Nav.Link as={ProtectedLink} to={`/`}>
        <div className="link-body">
          <OverlayTrigger
            placement="bottom"
            popperConfig={popperConfig}
            overlay={(props) => (
              <Tooltip id="stats-tooltip" {...props}>
                Статистика
              </Tooltip>
            )}
          >
            <span>
              <FaAlignLeft style={{ rotate: "270deg" }} />{" "}
            </span>
          </OverlayTrigger>{" "}
          <span className="nav-link-label">Статистика</span>
        </div>
      </Nav.Link>
    );
  }
  return (
    <Dropdown align="end" className="stat-menu">
      <Dropdown.Toggle as={Nav.Link} align="end">
        <div className="link-body">
          <OverlayTrigger
            placement="bottom"
            popperConfig={popperConfig}
            overlay={(props) => (
              <Tooltip id="stats-tooltip" {...props}>
                Статистика
              </Tooltip>
            )}
          >
            <span>
              <FaAlignLeft style={{ rotate: "270deg" }} />{" "}
            </span>
          </OverlayTrigger>{" "}
          <span className="nav-link-label">Статистика</span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Visible when={not(isLogged())}>
          <Dropdown.Item fluid className="text-center m-2" style={{ fontSize: "1rem" }}>
            Моля влезте с потребителския си профил, за да видите информацията в тази секция.
          </Dropdown.Item>
        </Visible>
        <Visible when={isLogged()}>
          <Dropdown.Item onClick={() => navigate(`/stats/acts/${new Date().getFullYear()}`)}>
            <FormattedMessage id="stats.menu.item.acts-by-year" />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigate(`/stats/acts/${dayjs().format("YYYY/M")}`)}>
            <FormattedMessage id="stats.menu.item.acts-by-month" />
          </Dropdown.Item>
          <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)}>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() =>
                navigate(`/stats/editors/${new Date().getFullYear()}/${dayjs().week()}`)
              }
            >
              <FormattedMessage id="stats.menu.item.editor" />
            </Dropdown.Item>
          </Visible>
        </Visible>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatsMenu;
