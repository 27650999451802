/* eslint-disable react/jsx-props-no-spreading */
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import LabelValue from "../../components/label-value";
import PreloadingLink from "../../components/PreloadingLink";
import { withAuth } from "../../contexts/auth.context";
import { Selection } from "../../hooks/useMultipleSelection";
import { Chat } from "../../models/Chat.model";
import { prefetchChat, useChatsAdmin } from "../../services/chat-services";
import { DocumentStatusBadge } from "../../services/document-services";
import ResultsTable from "./ResultsTable/results-table";
import ResultsTableHeader from "./ResultsTable/results-table-header";

const ChatSearchResults = () => {
  const results = useChatsAdmin();
  const intl = useIntl();

  return (
    <ResultsTable
      results={results}
      header={() => (
        <ResultsTableHeader
          sortOptions={[
            {
              value: "createdOn",
              label: "Дата на създаване",
            },
          ]}
        />
      )}
      itemToSelection={itemToSelection}
      allowBulkLoad
      row={(item) => (
        <ResultsTable.ResultTableRow>
          <PreloadingLink
            to={`/ask-lexebra/${item.id}`}
            state={{ title: item.title }}
            preload={(cache) => prefetchChat(item.id, cache)}
          >
            {item.title}
          </PreloadingLink>
          {item.query && <div>{item.query}</div>}
          {item.user && <LabelValue label="Зададен от">{item.user.name}</LabelValue>}
          {item.user && (
            <LabelValue label="Дата">
              {intl.formatDate(dayjs(item.createdOn, "YYYY-MM-DD").toDate(), {
                month: "long",
                day: "2-digit",
                year: "numeric",
              })}
            </LabelValue>
          )}
          <ResultsTable.RowIndicators item={item}>
            <DocumentStatusBadge status={item.status} />
          </ResultsTable.RowIndicators>
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

const itemToSelection = (item: Chat): Selection => ({
  _id: item.id,
  title: item.title,
  href: `/ask-lexebra/${item.id}`,
});

export default withAuth(ChatSearchResults);
