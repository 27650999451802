import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DayPicker, { DayModifiers } from "react-day-picker";
import { FaCalendar, FaList, FaLock } from "react-icons/fa";
import { RiFocus3Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { InlineLinkButton } from "../../components/Button/button";
import Collapse from "../../components/Collapse";
import ErrorBoundary from "../../components/ErrorBoundary";
import { Privileges, useAuth } from "../../contexts/auth.context";
import { useNavigationDrawerContext } from "../../contexts/navigation-drawer.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { Bulletin } from "../../models/Bulletin.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { useBulletinFocusedActs, useBulletins } from "../../services/bulletin-services";
import { scrollToTargetAdjusted } from "../JudgementPage/judgmenet-view-page-metadata";
export type Mode = "view" | "edit";

type BulletinPageNavigationProps = {
  bulletin: EntityModel<Bulletin>;
};
const BulletinPageNavigation: React.FC<BulletinPageNavigationProps> = ({ bulletin }) => {
  const value = useMemo(
    () => dayjs(bulletin.publishDate, "DD.MM.YYYY").toDate(),
    [bulletin.publishDate]
  );
  const [selectedMonth, setSelectedMonth] = useState<Date>();
  const navigate = useNavigate();

  const ref = useRef<DayPicker>(null);
  useEffect(() => {
    setSelectedMonth(value);
  }, [value]);
  const bulletins = useBulletins(selectedMonth);
  const auth = useAuth();

  const { setIsOpen } = useNavigationDrawerContext();
  const onChange = (date: Date, modifiers: DayModifiers) => {
    if (modifiers.restricted) {
      if (!auth.currentUser) {
        auth.setShowLoginModal(true, {
          successCallback: () =>
            navigate(
              `/bulletins/${
                bulletins.data?._embedded.items.find(
                  (bul) => bul.publishDate === dayjs(date).format("DD.MM.YYYY")
                )?.id
              }`
            ),
          cancelable: true,
        });
      } else {
        auth.setShowSubscriptionModal(true);
      }
    } else if (!modifiers.disabled) {
      navigate(
        `/bulletins/${
          bulletins.data?._embedded.items.find(
            (bul) => bul.publishDate === dayjs(date).format("DD.MM.YYYY")
          )?.id
        }`
      );
      setIsOpen(false);
    } else if (auth.hasPrivilige(Privileges.EDIT_BULLETINS)) {
      if (date.getDay() === 0 || date.getDay() === 6) {
        toast.error("Бюлетин може да бъде създаден само за работен ден");
      } else if (!dayjs(date).isBefore(dayjs(), "date")) {
        toast.error("Бюлетин може да бъде създаден само за изминал ден");
      } else {
        navigate(`/bulletins/new`, { state: { date: dayjs(date).format("DD.MM.YYYY") } });
      }
    }
  };

  const [showFocused, setShowFocused] = useState(false);
  return (
    <div className="collapse-container">
      <Collapse icon={<FaCalendar />} label={"Дата"} defaultExpanded={true}>
        <DayPicker
          onMonthChange={setSelectedMonth}
          selectedDays={value}
          onDayClick={onChange}
          ref={ref}
          initialMonth={value}
          months={dayjs.months()}
          weekdaysShort={dayjs.weekdaysMin(false)}
          weekdaysLong={dayjs.weekdays(false)}
          firstDayOfWeek={1}
          disabledDays={(date) =>
            !bulletins.data?._embedded.items.find(
              (bul) => bul.publishDate === dayjs(date).format("DD.MM.YYYY")
            )
          }
          renderDay={(date) => {
            return (!auth.currentUser || !auth.hasPrivilige(Privileges.VIEW_DOCUMENTS)) &&
              bulletins.data?._embedded.items.find(
                (bul) => bul.publishDate === dayjs(date).format("DD.MM.YYYY")
              )?.accessMode === DocumentAccessMode.PAID ? (
              <OverlayTrigger
                placement="top"
                overlay={(props) => (
                  <Tooltip id="restricted-tooltip" {...props}>
                    Изисква платен абонамент
                  </Tooltip>
                )}
              >
                <span>
                  {dayjs(date).date()}
                  <FaLock />
                </span>
              </OverlayTrigger>
            ) : (
              dayjs(date).date()
            );
          }}
          modifiers={{
            restricted: (date) =>
              !!(
                (!auth.currentUser || !auth.hasPrivilige(Privileges.VIEW_DOCUMENTS)) &&
                bulletins.data?._embedded.items.find(
                  (bul) => bul.publishDate === dayjs(date).format("DD.MM.YYYY")
                )?.accessMode === DocumentAccessMode.PAID
              ),
          }}
        />
      </Collapse>
      <Collapse icon={<FaList />} label={"Съдържание"} defaultExpanded={true}>
        <ErrorBoundary message="Възникна грешка при зареждането на данните">
          <TableOfContents />
        </ErrorBoundary>
      </Collapse>

      <Collapse
        icon={<RiFocus3Line />}
        label={"На Фокус"}
        defaultExpanded={true}
        className={showFocused ? "" : "d-none"}
      >
        <ErrorBoundary message="Възникна грешка при зареждането на данните">
          <FocusedActs bulletin={bulletin} setShow={setShowFocused} />
        </ErrorBoundary>
      </Collapse>
    </div>
  );
};

const fetchSections = () => {
  const elements = Array.from(document.querySelectorAll(".act-text .main-section h2")).map(
    (elem) => {
      if (!elem.id) {
        elem.id = "_" + uuid();
      }
      return {
        id: elem.id,
        text: (elem as any).innerText as string,
      };
    }
  );
  return elements;
};

const TableOfContents = () => {
  const [sections, setSections] = useState<{ text: string; id: string }[]>([]);
  const { bulletinId } = useParams();
  useEffect(() => {
    setSections(fetchSections());
  }, [bulletinId]);

  const { setIsOpen } = useNavigationDrawerContext();

  return (
    <nav className="toc">
      <ul>
        {sections.map((section) => (
          <li key={section.id} className="head2">
            <InlineLinkButton
              onClick={(e: any) => {
                e.preventDefault();
                setTimeout(() => {
                  scrollToTargetAdjusted(document.querySelector(`#${section.id}`)!);
                  setIsOpen(false);
                }, 10);
                return false;
              }}
            >
              {section.text}
            </InlineLinkButton>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const FocusedActs: React.FC<BulletinPageNavigationProps & { setShow: (show: boolean) => void }> = ({
  bulletin,
  setShow,
}) => {
  const acts = useBulletinFocusedActs(bulletin.id);
  const { setSelectedActId } = useSelectedActContext();
  useEffect(() => {
    setShow(!!acts.data?._embedded.items.length);
  }, [setShow, acts]);
  return (
    <div className="section">
      {acts.data?._embedded.items.map((relatedAct) => (
        <InlineLinkButton
          onClick={() => setSelectedActId(relatedAct.id)}
          title={relatedAct.title}
          key={relatedAct.id}
        >
          {relatedAct.title}
        </InlineLinkButton>
      ))}
    </div>
  );
};

export default BulletinPageNavigation;
