import "@recogito/recogito-js/dist/recogito.min.css";
import React, { useCallback, useRef } from "react";
import { Button, ListGroup, Offcanvas, Overlay, Tooltip } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { useHotkeys } from "react-hotkeys-hook";
import { FaClone, FaHighlighter, FaSourcetree, FaTag } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as AiIcon } from "../../assets/ai.svg";
import { Privileges, useAuth } from "../../contexts/auth.context";
import { useHighlightContext } from "../../contexts/highlight.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { TextSelectionReturn, useTextSelection } from "../../hooks/useTextSelection";
import { UserDocumentAnno } from "../../models/UserDocumentAnno.model";
import { UserDocumentAnnoScope } from "../../models/UserDocumentAnnoScope.enum";
import { addUserDocumentAnno } from "../../services/user-services";
import { toggleCanvas } from "./judgement-view-page";
import "./judgement-view-page.scss";
import { rangeToSelection, trimRange } from "./selection-utils";

export const SelectedActTextPopover: React.FC<{
  recogito: any;
  target?: HTMLElement;
  actId: string;
  updateAnnotations: (annotations: UserDocumentAnno[]) => void;
  canAddAnno: boolean;
}> = ({ recogito, target, actId, updateAnnotations, canAddAnno }) => {
  const textSelecton = useTextSelection(target);
  const { clientRect, isCollapsed, textContent, isOutOfScreen, reset } = textSelecton;
  const ref = useRef(null);
  const { isLoginSessionActive } = useAuth();

  const { setIncludeUserAnnotations } = useHighlightContext();

  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  const addAnnotation = useCallback(
    async (category: string, scope: UserDocumentAnnoScope) => {
      const selectedRange = trimRange(window.getSelection()!.getRangeAt(0));
      const anno = rangeToSelection(selectedRange, document.querySelector("#act-body")!);
      anno.body.push({
        type: "TextualBody",
        purpose: "scope",
        value: scope,
      });
      anno.body.push({
        type: "TextualBody",
        purpose: "tagging",
        value: category,
      });
      recogito.addAnnotation(anno);
      if (isLoginSessionActive()) {
        const backendAnno = await addUserDocumentAnno(actId, anno);
        updateAnnotations(backendAnno);
      }
      toggleCanvas(recogito, q);
      recogito.pagemap.redraw();
      reset();
      setIncludeUserAnnotations(true);

      const selection = window.getSelection();
      selection?.removeAllRanges();
    },
    [actId, recogito, reset, updateAnnotations, isLoginSessionActive, q, setIncludeUserAnnotations]
  );

  useHotkeys(
    "ctrl+d,command+d",
    (e) => {
      e.preventDefault();
      addAnnotation("Без етикет", UserDocumentAnnoScope.PRIVATE).catch(console.error);
    },
    {
      enabled: !!textContent?.trim(),
    },
    [addAnnotation]
  );

  if (isMobileOnly) {
    return (
      <Offcanvas
        show={!isCollapsed && !!textContent?.trim() && !!clientRect}
        onHide={reset}
        placement="bottom"
        scroll
        backdrop={false}
        id="annotation-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Маркиран текст</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SelectedActTextPopoverContentsMobile
            recogito={recogito}
            textSelection={textSelecton}
            actId={actId}
            updateAnnotations={updateAnnotations}
            addAnnotation={addAnnotation}
            canAddAnno={canAddAnno}
          />
        </Offcanvas.Body>
      </Offcanvas>
    );
  }

  return (
    <>
      <div
        ref={ref}
        style={
          !isCollapsed && textContent?.trim() && clientRect
            ? {
                left: clientRect.left + clientRect.width / 2,
                top: window.scrollY + clientRect.top + (isOutOfScreen ? clientRect.height : 0),
                position: "absolute",
              }
            : { marginLeft: "-999px", position: "absolute" }
        }
      ></div>
      <Overlay
        key={textContent}
        target={ref}
        show={!isCollapsed && !!textContent?.trim() && !!clientRect}
        placement={isOutOfScreen ? "bottom" : "top"}
        popperConfig={{
          strategy: "fixed",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -4],
              },
            },
          ],
        }}
      >
        <Tooltip id="annotation-tooltip">
          <SelectedActTextPopoverContents
            recogito={recogito}
            textSelection={textSelecton}
            actId={actId}
            updateAnnotations={updateAnnotations}
            addAnnotation={addAnnotation}
            canAddAnno={canAddAnno}
          />
        </Tooltip>
      </Overlay>
    </>
  );
};

export const SelectedActTextPopoverContentsMobile: React.FC<{
  recogito: any;
  textSelection: TextSelectionReturn;
  actId: string;
  addAnnotation: (category: string, scope: UserDocumentAnnoScope) => Promise<void>;
  updateAnnotations: (annotations: UserDocumentAnno[]) => void;
  canAddAnno: boolean;
}> = ({ recogito, textSelection, actId, updateAnnotations, addAnnotation, canAddAnno }) => {
  const { textContent, reset } = textSelection;
  const { setSelectedActId } = useSelectedActContext();
  const auth = useAuth();
  const accessToAskLexebra = auth.hasPrivilige(Privileges.VIEW_CHATS);
  return (
    <ListGroup variant="flush">
      {accessToAskLexebra && (
        <ListGroup.Item
          onClick={async (e) => {
            textContent && setSelectedActId(`chat:${textContent}`);
          }}
        >
          <AiIcon width="1rem" height="1rem" /> Попитай Лексебра
        </ListGroup.Item>
      )}

      <ListGroup.Item
        onClick={async (e) => {
          await navigator.clipboard.writeText(textContent || "");
          toast.info("Текстът бе копиран в клипборда");
          reset();

          const selection = window.getSelection();
          selection?.removeAllRanges();
        }}
      >
        <FaClone /> Копирай
      </ListGroup.Item>

      <ListGroup.Item
        onMouseDown={async (e) => {
          await addAnnotation("Без етикет", UserDocumentAnnoScope.PRIVATE);
          e.stopPropagation();
        }}
      >
        <FaHighlighter /> Подчертай
      </ListGroup.Item>
      {canAddAnno && (
        <>
          <ListGroup.Item>
            <FaSourcetree /> Подчертай:
          </ListGroup.Item>
          <ListGroup.Item
            onMouseDown={async (e) => {
              await addAnnotation("Въпрос", UserDocumentAnnoScope.PUBLIC);
              e.stopPropagation();
            }}
          >
            <FaTag className="invisible" /> Въпрос
          </ListGroup.Item>
          <ListGroup.Item
            onMouseDown={async (e) => {
              await addAnnotation("Мотиви", UserDocumentAnnoScope.PUBLIC);
              e.stopPropagation();
            }}
          >
            <FaTag className="invisible" /> Мотиви
          </ListGroup.Item>
          <ListGroup.Item
            onMouseDown={async (e) => {
              await addAnnotation("Отговор", UserDocumentAnnoScope.PUBLIC);
              e.stopPropagation();
            }}
          >
            <FaTag className="invisible" /> Отговор
          </ListGroup.Item>
          <ListGroup.Item
            onMouseDown={async (e) => {
              await addAnnotation("Основание за допускане", UserDocumentAnnoScope.PUBLIC);
              e.stopPropagation();
            }}
          >
            <FaTag className="invisible" /> Основание за допускане
          </ListGroup.Item>
          <ListGroup.Item
            onMouseDown={async (e) => {
              await addAnnotation("По същество", UserDocumentAnnoScope.PUBLIC);
              e.stopPropagation();
            }}
          >
            <FaTag className="invisible" /> По същество
          </ListGroup.Item>
        </>
      )}
    </ListGroup>
  );
};

export const SelectedActTextPopoverContents: React.FC<{
  recogito: any;
  textSelection: TextSelectionReturn;
  actId: string;
  addAnnotation: (category: string, scope: UserDocumentAnnoScope) => Promise<void>;
  updateAnnotations: (annotations: UserDocumentAnno[]) => void;
  canAddAnno: boolean;
}> = ({ recogito, textSelection, actId, updateAnnotations, addAnnotation, canAddAnno }) => {
  const auth = useAuth();
  const canEditSummaries = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
  const { textContent, reset } = textSelection;

  const { setSelectedActId } = useSelectedActContext();
  const accessToAskLexebra = auth.hasPrivilige(Privileges.VIEW_CHATS);
  return (
    <div className="d-flex align-items-center">
      {accessToAskLexebra && (
        <Button
          variant="link"
          className="m-auto"
          onMouseUp={(e) => e.stopPropagation()}
          onClick={(e) => {
            textContent && setSelectedActId(`chat:${textContent}`);
          }}
        >
          <AiIcon title="Попитай Лексебра" width="1rem" height="1rem" />
        </Button>
      )}
      <Button
        variant="link"
        className="m-auto"
        onMouseUp={(e) => e.stopPropagation()}
        onClick={async (e) => {
          await navigator.clipboard.writeText(textContent || "");
          toast.info("Текстът бе копиран в клипборда");
          reset();

          const selection = window.getSelection();
          selection?.removeAllRanges();
        }}
      >
        {canEditSummaries ? (
          <FaClone title="Копирай маркирания текст" />
        ) : (
          <>
            <FaClone /> Копирай
          </>
        )}
      </Button>
      <Button
        variant="link"
        className="m-auto"
        onMouseUp={(e) => e.stopPropagation()}
        onClick={async (e) => {
          await addAnnotation("Без етикет", UserDocumentAnnoScope.PRIVATE);
        }}
      >
        {canEditSummaries ? (
          <FaHighlighter title="Лично подчертаване" />
        ) : (
          <>
            <FaHighlighter /> Подчертай
          </>
        )}
      </Button>
      {canAddAnno && (
        <>
          |
          <FaSourcetree /> Подчертай:
          <>
            <Button
              variant="link"
              className="m-auto"
              onMouseUp={(e) => e.stopPropagation()}
              onClick={async (e) => {
                await addAnnotation("Въпрос", UserDocumentAnnoScope.PUBLIC);
                e.stopPropagation();
              }}
              title="Въпрос"
            >
              В
            </Button>
            |{" "}
            <Button
              variant="link"
              className="m-auto"
              onMouseUp={(e) => e.stopPropagation()}
              onClick={async (e) => {
                await addAnnotation("Мотиви", UserDocumentAnnoScope.PUBLIC);
                e.stopPropagation();
              }}
              title="Мотиви"
            >
              М
            </Button>
            |{" "}
            <Button
              variant="link"
              className="m-auto"
              onMouseUp={(e) => e.stopPropagation()}
              onClick={async (e) => {
                await addAnnotation("Отговор", UserDocumentAnnoScope.PUBLIC);
                e.stopPropagation();
              }}
              title="Отговор"
            >
              О
            </Button>
            |{" "}
            <Button
              variant="link"
              className="m-auto"
              onMouseUp={async (e) => {
                await addAnnotation("Основание за допускане", UserDocumentAnnoScope.PUBLIC);
                e.stopPropagation();
              }}
              onClick={async (e) => {}}
              title="Основание за допускане"
            >
              Осн
            </Button>
            |{" "}
            <Button
              variant="link"
              className="m-auto"
              onMouseUp={async (e) => {
                await addAnnotation("По същество", UserDocumentAnnoScope.PUBLIC);
                e.stopPropagation();
              }}
              onClick={async (e) => {}}
              title="По същество"
            >
              Същ
            </Button>
          </>
        </>
      )}
    </div>
  );
};

export default SelectedActTextPopover;
