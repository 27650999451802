import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LexebraEditor from "../../models/LexebraEditor.model";
import { useEditorStats } from "../../services/user-services";

const EditorInfo: React.FC<{ editor?: LexebraEditor }> = ({ editor }) => {
  const stats = useEditorStats(editor?.id);
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={(props) => (
        <Tooltip id="author-tooltip" {...props}>
          {editor?.name && (
            <div style={{ whiteSpace: "nowrap" }}>
              <div>
                <strong>{editor.name}</strong>
              </div>
              <div>{editor.degree}</div>
              {stats && (
                <>
                  {stats.numberOfSummaries > 0 && (
                    <div>{stats.numberOfSummaries} публикувани резюмета</div>
                  )}
                  {stats.numberOfAnnotatedActs > 0 && (
                    <div>{stats.numberOfAnnotatedActs} анотирани акта</div>
                  )}
                  {stats.numberOfHighlightedActs > 0 && (
                    <div>{stats.numberOfHighlightedActs} подчертани акта</div>
                  )}
                  {stats.numberOfChats > 0 && (
                    <div>{stats.numberOfChats} въпроса в 'Попитай Лексебра'</div>
                  )}
                </>
              )}
            </div>
          )}
        </Tooltip>
      )}
    >
      <span className="fw-normal"> {getInitials(editor?.name || "") + " "}</span>
    </OverlayTrigger>
  );
};

export const getInitials = (name?: string) => {
  if (!name) return "";
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = Array.from(name.matchAll(rgx)).map((match) => match[1]) || [];

  return initials.join("");
};

export default EditorInfo;
