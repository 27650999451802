import { IoMdShare } from "react-icons/io";
import { useIntl } from "react-intl";
import ShareOnSocial from "react-share-on-social";
import DocumentAction from "./document-action";

const DocumentShareAction: React.FC<{ link: string; id: string; title: string; desc?: string }> = ({
  link,
  id,
  title,
  desc,
}) => {
  const intl = useIntl();

  return (
    <DocumentAction
      handler={() => {}}
      icon={
        <ShareOnSocial
          textToShare={title}
          link={link}
          linkTitle={title}
          shareTo={["facebook", "linkedin", "email"]}
          linkMetaDesc={desc}
          copyToClipboardText={intl.formatMessage({ id: "document-share-actions.copy-link" })}
          copySuccessText={intl.formatMessage({ id: "document-share-actions.link-copied" })}
          closeText={intl.formatMessage({ id: "document-actions.close" })}
          // linkFavicon={favicon}
          noReferer
        >
          <IoMdShare />
        </ShareOnSocial>
      }
      label={intl.formatMessage({ id: "document-actions.share" })}
    />
  );
};

export default DocumentShareAction;
