import { useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FaExclamationTriangle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../components/Button/button";
import { useAuth } from "../contexts/auth.context";

const NoSubscriptionModal = () => {
  const { setShowSubscriptionModal } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const addBlur = useCallback(() => {
    (document.querySelector(".router > div")! as HTMLElement).style.filter = "blur(2px)";
  }, []);
  const removeBlur = useCallback(() => {
    (document.querySelector(".router > div")! as HTMLElement).style.filter = "";
  }, []);

  useEffect(() => {
    pathname !== "/" && addBlur();
    return removeBlur;
  });

  return (
    <Modal show={true} dialogClassName="subscription-dialog">
      <Modal.Body>
        <div className="d-flex">
          <span
            style={{
              alignSelf: "center",
              marginRight: "10px",
            }}
          >
            <FaExclamationTriangle
              style={{
                width: "50px",
                height: "50px",
                alignSelf: "center",
                color: "var(--view-page-btn-link)",
              }}
            />
          </span>
          <span>
            Текущият Ви абонамент не включва достъп до избраната функционалност.
            <br /> За да проверите статуса на текущия Ви абонамент моля посетете профилната Ви{" "}
            <Link to="/user/profile" onClick={() => setShowSubscriptionModal(false)}>
              страница
            </Link>
            .
          </span>
        </div>
        <br />
        <PrimaryButton
          onClick={() => {
            setShowSubscriptionModal(false);
            navigate("/");
          }}
          messageId={pathname === "/" ? "button.close" : "login.modal.button.back-to-home"}
        />
      </Modal.Body>
    </Modal>
  );
};

export default NoSubscriptionModal;
