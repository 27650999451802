import { EntityModel } from "hateoas-hal-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ActionButton, PrimaryButton, SecondaryButton } from "../../components/Button/button";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import { Chat, ChatInitiationContext, ChatLegalArea } from "../../models/Chat.model";
import { regenerateChat, startChat, StartChatInput } from "../../services/chat-services";
import { ChatViewPageMode } from "./chat-view-page";
import "./gpt-input.scss";

const SearchInputLexebraGPT: React.FC<{
  chatId?: string;
  query?: string;
  mode: ChatViewPageMode;
  setMode: (mode: ChatViewPageMode) => void;
  onCreate: (chat: EntityModel<Chat>) => void;
  submitting: boolean;
  setSubmitting: (mode: boolean) => void;
  initiationContext: ChatInitiationContext;
}> = ({ query, mode, setMode, chatId, onCreate, submitting, setSubmitting, initiationContext }) => {
  const [inputValue, setInputValue] = useState(query || "");
  const [error, setError] = useState("");
  const [legalArea, setLegalArea] = useState(ChatLegalArea.CIVIL);
  const onSearch = useCallback(
    async (searchText: string) => {
      if (!searchText) {
        return;
      }
      setSubmitting(true);
      const handler = chatId ? (data: StartChatInput) => regenerateChat(chatId, data) : startChat;
      handler({ query: searchText, initiationContext, legalArea })
        .then((chat) => {
          setError("");
          chatId ? setMode("view") : onCreate(chat);
        })
        .catch((e) => {
          console.log(e);
          if (e.payload?.code === "LEX-001") {
            setError(e.payload.message);
          } else {
            toast.error("Възникна грешка при генерирането на отговора. Моля, опитайте отново.");
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [chatId, setSubmitting, setMode, onCreate, initiationContext, legalArea]
  );

  // useEffect(() => {
  //   setInputValue(chat?.title || "");
  // }, [chat?.title]);

  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      autoResizeTextarea();
    }
  }, []);

  const autoResizeTextarea = () => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const charLimit = 800;
  const getCharCounterClass = () => {
    if (inputValue.length >= charLimit) {
      return "danger";
    } else if (inputValue.length >= charLimit * 0.8) {
      return "warning";
    } else {
      return "primary";
    }
  };

  const options = [
    { value: ChatLegalArea.CIVIL, label: "Гражданско и търговско право" },
    { value: ChatLegalArea.CRIMINAL, label: "Наказателно право" },
    { value: ChatLegalArea.ADMINISTRATIVE, label: "Административно право" },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div className="textarea-button-container">
        <div className="d-flex w-100" style={{ opacity: 0.7 }}>
          <textarea
            className="form-control"
            placeholder="Моля въведете Вашият правен въпрос тук, напр. 'От кога започва да тече погасителна давност за дължимите вноски по необслужван банков кредит?'"
            ref={ref}
            value={inputValue}
            rows={2}
            onChange={(e) => {
              setInputValue(e.target.value?.substring(0, charLimit) || "");
              autoResizeTextarea();
            }}
            maxLength={charLimit}
            disabled={submitting}
            style={{ resize: "none", overflow: "hidden" }}
          />
          {!!inputValue.length && (
            <Badge
              bg={getCharCounterClass()}
              style={{ height: "fit-content" }}
              className="me-2 mt-2"
            >
              {inputValue.length}/{charLimit}
            </Badge>
          )}
        </div>

        {initiationContext === ChatInitiationContext.USER_QUESTION && (
          <div className="d-flex w-100 text-muted mt-2 mb-3 legal-field">
            <Form.Label as="legend" className="me-3 fs-6" style={{ width: "fit-content" }}>
              Област:
            </Form.Label>
            {options.map((option, idx) => (
              <Form.Check
                key={idx}
                type="radio"
                name="legalField"
                id={`radio-${idx}`}
                label={option.label}
                value={option.value}
                checked={legalArea === option.value}
                disabled={mode === "edit"}
                onChange={(e) => setLegalArea(e.target.value as ChatLegalArea)}
                className="mb-1 me-2"
              />
            ))}
          </div>
        )}
        <div className="d-flex gap-1 pb-3 w-100 flex-grow-0">
          {mode !== "edit" ? (
            <>
              <ActionButton
                messageId=""
                disabled={inputValue.trim() === "" || submitting || inputValue.length > charLimit}
                onClick={() => onSearch(inputValue)}
                submitting={submitting}
              >
                Попитай Лексебра
              </ActionButton>
              <div className=" flex-grow-1"></div>
            </>
          ) : (
            <>
              <PrimaryButton
                messageId=""
                disabled={inputValue.trim() === "" || submitting || query === inputValue}
                onClick={() => onSearch(inputValue)}
                submitting={submitting}
              >
                Прегенерирай
              </PrimaryButton>
              <SecondaryButton
                onClick={() => setMode("view")}
                messageId={"button.cancel"}
                disabled={submitting}
              />
              <div className=" flex-grow-1"></div>
              <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)}>
                <PrimaryButton
                  messageId=""
                  disabled={submitting || query !== inputValue}
                  onClick={() => navigate(`/ask-lexebra/${chatId}/edit`)}
                  submitting={submitting}
                >
                  Коригирай
                </PrimaryButton>
              </Visible>
            </>
          )}
        </div>
        <div className="d-flex w-100 fst-italic">
          {mode === "edit" && "* Редакцията на въпросa ще изтрие предишните отговори."}
        </div>
      </div>
      <div className="search-results-container">
        {error && (
          <ChatViewPageContainer>
            <pre>{error}</pre>
          </ChatViewPageContainer>
        )}
      </div>
    </>
  );
};

const ChatViewPageContainer: React.FC = ({ children }) => {
  return (
    <>
      <Card className={`mb-3 search-card`}>
        <Card.Body style={{ flexDirection: "column" }}>
          <Card.Text className="fw-bold">Отговор: </Card.Text>
          <Card.Text>{children}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default SearchInputLexebraGPT;
