import { FaLink, FaNewspaper } from "react-icons/fa";
import { useIntl } from "react-intl";
import { ReactComponent as AiIcon } from "../../assets/ai.svg";
import { InlineLinkButton } from "../../components/Button/button";
import ErrorBoundary from "../../components/ErrorBoundary";
import Collapse from "../../components/SimpleCollapse";
import { Privileges, useAuth } from "../../contexts/auth.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { useSummaryContext } from "../../contexts/summary.context";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { CollapsibleFiltersPanel } from "../../pages/SearchPage/FiltersSidebar/filters";
import { useSummaryAiReferences } from "../../services/summary-services";
import { ResolvedReferencesPanel } from "../JudgementPage/judgement-page-navigation";
import CaseActsTab from "./case-acts-tab";
import MissingInformationCard from "./missing-info-card";
import RelatedActsTab from "./related-acts-tab";
import TableOfContentsTab from "./table-of-contents-tab";

export type Mode = "view" | "edit" | "landing";

export type SummaryPageNavigationProps = {
  mode: Mode;
  toggleSection?: (section: string, status?: any) => void;
};
const SummaryPageNavigation: React.FC<SummaryPageNavigationProps> = ({ mode, toggleSection }) => {
  return (
    <>
      <div className="filters-sidebar" style={{ fontSize: "0.90rem" }}>
        {mode === "edit" && (
          <Collapse icon={<FaLink />} label="Актове по делото">
            <CaseActsTab />
          </Collapse>
        )}

        <Collapse icon={<FaLink />} label="Съдържание" defaultExpanded={mode !== "edit"}>
          {mode !== "edit" ? (
            <CollapsibleFiltersPanel
              collapseHeight="250px"
              openedLabel="Покажи по-малко"
              closedLabel="Покажи всичко"
              initiallyOpened
            >
              <TableOfContentsTab toggleSection={toggleSection} />
            </CollapsibleFiltersPanel>
          ) : (
            <TableOfContentsTab toggleSection={toggleSection} />
          )}
        </Collapse>
        {mode !== "edit" && <AiRelatedActsPanel />}
        {mode !== "edit" && <BulletinPanel />}

        <Collapse icon={<FaLink />} label="Свързани актове" defaultExpanded={mode !== "edit"}>
          <RelatedActsTab />
        </Collapse>

        <ResolvedReferences />
      </div>
      {mode === "edit" && <MissingInformationCard />}
    </>
  );
};

const AiRelatedActsPanel = () => {
  const intl = useIntl();
  const { setSelectedActId } = useSelectedActContext();

  const { summary } = useSummaryContext();

  const references = useSummaryAiReferences(summary.id, summary.qas?.[0].id);

  const auth = useAuth();

  const accessToAskLexebra = auth.hasPrivilige(Privileges.VIEW_CHATS);

  return accessToAskLexebra &&
    references &&
    references.acts.length + references.questions.length > 0 ? (
    <Collapse
      icon={<AiIcon width="1.1rem" height="1.1rem" className="ask-lexebra-button" />}
      label={intl.formatMessage({ id: "summary.page.labels.ai-references" })}
    >
      <ErrorBoundary message="Възникна грешка при зареждането на данните">
        <div className="section">
          {references?.acts
            .filter(
              (relatedAct) =>
                relatedAct.status !== DocumentStatus.DELETED &&
                relatedAct.id !== summary.relatedJudgementAct.id
            )
            .map((relatedAct) => (
              <>
                <InlineLinkButton
                  onClick={() => setSelectedActId(relatedAct.id)}
                  title={relatedAct.title}
                  key={relatedAct.id}
                >
                  {relatedAct.title}
                </InlineLinkButton>
                [{relatedAct.keywords}]
              </>
            ))}
        </div>
      </ErrorBoundary>
    </Collapse>
  ) : null;
};

const BulletinPanel = () => {
  const intl = useIntl();
  const { setSelectedActId } = useSelectedActContext();

  const { summary } = useSummaryContext();

  const { bulletin } = summary;

  return bulletin ? (
    <Collapse
      icon={<FaNewspaper />}
      label={intl.formatMessage({ id: "summary.page.labels.bulletin" })}
    >
      <ErrorBoundary message="Възникна грешка при зареждането на данните">
        <div className="section">
          <InlineLinkButton onClick={() => setSelectedActId(`bulletin:${bulletin.id}`)}>
            {bulletin.title}
          </InlineLinkButton>
        </div>
      </ErrorBoundary>
    </Collapse>
  ) : null;
};

const ResolvedReferences = () => {
  const { summary } = useSummaryContext();
  return <ResolvedReferencesPanel actId={summary.relatedJudgementAct.id} showOnlyCitedFrom />;
};

export default SummaryPageNavigation;
