import { FunctionComponent } from "react";
import { RiAwardFill, RiAwardLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import DocumentStatus from "../../models/DocumentStatus.enum";
import DocumentAction from "./document-action";

interface DocumentReviewActionProps {
  setStatus: (status: DocumentStatus) => Promise<any>;
  document: {
    id: string;
    status: DocumentStatus;
  };
}

const DocumentReviewAction: FunctionComponent<DocumentReviewActionProps> = ({
  document,
  setStatus,
}) => {
  const updateStatus = async (status: DocumentStatus) => {
    try {
      await setStatus(status);
      toast.success("Успешно променихте статуса на документа");
    } catch (error) {
      console.error(error);
      toast.error("Възникна грешка при промяната на статуса на документа");
    }
  };
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)}>
      {document.status === DocumentStatus.PUBLIC && (
        <DocumentAction
          handler={() => updateStatus(DocumentStatus.REVIEW)}
          icon={<RiAwardLine />}
          label='Сложи "За преглед"'
        />
      )}
      {document.status === DocumentStatus.REVIEW && (
        <DocumentAction
          handler={() => updateStatus(DocumentStatus.PUBLIC)}
          icon={<RiAwardFill />}
          label='Премахни "За преглед"'
        />
      )}
    </Visible>
  );
};

export default DocumentReviewAction;
