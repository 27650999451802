import memoizee from "memoizee";
import { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as ReactDOM from "react-dom";
import { FaInfoCircle, FaPlusCircle } from "react-icons/fa";
import ReactQuill from "react-quill";
import AddRelatedActModal from "../../pages/EditorCollectionPage/add-related-act.modal";
import { fetchChat } from "../../services/chat-services";
import { fetchJudgementAct, judgementActHref } from "../../services/judgement-act-services";
import { fetchSummary, summaryHref } from "../../services/summary-services";
import IndicatorIcons from "../IndicatorIcons";
import RichTextEditor from "./rich-text-editor";

let BlockEmbed = ReactQuill.Quill.import("blots/block/embed");

class ActTitleBlot extends BlockEmbed {
  static roots = {};
  static create(value: string | HTMLElement) {
    let id: string;
    if (typeof value === "string") {
      id = value;
    } else if (!value.textContent) {
      id = value.dataset.id!;
    } else {
      return value;
    }
    let node: HTMLDivElement = super.create();
    node.dataset.id = id;

    fetchActWithCache(id).then((act) => {
      var title = document.createElement("div");
      title.className = "d-flex align-items-baseline";
      node.appendChild(title);

      var a = document.createElement("a");
      a.className = "link inline-link";
      var linkText = document.createTextNode(act.title);
      a.appendChild(linkText);
      a.href = judgementActHref(act.id);
      title.appendChild(a);

      var icons = document.createElement("div");
      icons.className = "ms-1 icons-container";
      title.appendChild(icons);
      ReactDOM.render(<IndicatorIcons item={act} />, icons);

      if (
        act.caseType &&
        act.caseType.code !== "CRIMINAL" &&
        act.caseType.code !== "CRIMINAL_PRIVATE"
      ) {
        var proceedingTitle = document.createElement("span");
        proceedingTitle.className = "meta-label";
        proceedingTitle.style.fontSize = "0.9rem";
        proceedingTitle.appendChild(document.createTextNode("Вид производство:"));
        node.appendChild(proceedingTitle);

        var proceedingValue = document.createElement("span");
        proceedingValue.className = "ms-1 meta-value";
        proceedingValue.style.fontSize = "0.9rem";
        proceedingValue.appendChild(document.createTextNode(act.proceeding?.shortName));
        node.appendChild(proceedingValue);
      }

      let quill = ReactQuill.Quill.find(
        document.querySelector("#editor-collection > .ql-container")!
      );
      quill.scroll.emitter.emit("text-change", this);
    });

    return node;
  }

  detach() {
    super.detach();
    ReactDOM.unmountComponentAtNode(this.domNode.querySelector(".icons-container"));
  }

  static value(domNode: HTMLElement) {
    return domNode;
  }
}
ActTitleBlot.blotName = "act-title";
ActTitleBlot.tagName = "div";
ActTitleBlot.className = "act-title";

ReactQuill.Quill.register(ActTitleBlot);

class SummaryTitleBlot extends BlockEmbed {
  static create(value: string | HTMLElement) {
    let id: string;
    if (typeof value === "string") {
      id = value;
    } else if (!value.textContent) {
      id = value.dataset.id!;
    } else {
      return value;
    }
    let node: HTMLDivElement = super.create();
    node.dataset.id = id;

    fetchSummaryWithCache(id).then((summary) => {
      var title = document.createElement("div");
      title.className = "d-flex align-items-baseline";
      node.appendChild(title);

      var a = document.createElement("a");
      a.className = "link inline-link";
      var linkText = document.createTextNode(summary.relatedJudgementAct.title + " /Резюме/");
      a.appendChild(linkText);
      a.href = summaryHref(summary.id);
      title.appendChild(a);

      var icons = document.createElement("div");
      icons.className = "ms-1";
      title.appendChild(icons);
      ReactDOM.render(<IndicatorIcons item={summary} />, icons);

      var div = document.createElement("div");
      div.classList.add("text-muted");
      div.appendChild(document.createTextNode(summary.shortTitle));
      node.appendChild(div);
      let quill = ReactQuill.Quill.find(
        document.querySelector("#editor-collection > .ql-container")!
      );
      quill.scroll.emitter.emit("blot-load", this);
    });

    return node;
  }

  static value(domNode: HTMLElement) {
    return domNode;
  }

  detach() {
    super.detach();
    ReactDOM.unmountComponentAtNode(this.domNode.querySelector(".icons-container"));
  }
}
SummaryTitleBlot.blotName = "summary-title";
SummaryTitleBlot.tagName = "div";
SummaryTitleBlot.className = "summary-title";

ReactQuill.Quill.register(SummaryTitleBlot);

class SummaryQuestionBlot extends BlockEmbed {
  static create(value: string | HTMLElement) {
    let id: string;
    if (typeof value === "string") {
      id = value;
    } else if (!value.textContent) {
      id = value.dataset.id!;
    } else {
      return value;
    }
    let node: HTMLDivElement = super.create();
    const [summaryId, questionId] = id.split("#");
    node.dataset.id = id;

    fetchSummaryWithCache(summaryId).then((summary) => {
      const question = summary.qas.find((qa) => qa.id === questionId)!;

      const metaProceeding = question.groundOfAppeal
        ? `<span class="meta-label">Основание за допускане:</span><span class="meta-value ms-1">${question.groundOfAppeal?.shortName}</span>`
        : "";
      const metaGround = summary.relatedJudgementAct.proceeding
        ? `<span class="meta-label">Вид производство:</span><span class="meta-value ms-1">${summary.relatedJudgementAct.proceeding?.shortName}</span><br/>`
        : "";

      const fragment = document
        .createRange()
        .createContextualFragment(
          `<div class="highlight-card card"><div class="card-body"><div class="card-subtitle h6"><span class="question-title">Въпрос</span>:</div><details><summary>${question.question}</summary>${question.answer}${metaProceeding}<br/>${metaGround}</details></div></div>`
        );
      var b = document.createElement("button");
      var linkText = document.createTextNode("x");
      b.appendChild(linkText);
      b.className = "inline-link btn btn-link btn-sm";
      b.onclick = (e: any) => {
        let quill = ReactQuill.Quill.find(
          document.querySelector("#editor-collection > .ql-container")!
        );
        let blot = ReactQuill.Quill.find(e.target.parentNode);
        let index = blot.offset(quill.scroll);
        var Delta = ReactQuill.Quill.import("delta");
        quill.updateContents(new Delta().retain(index).delete(1), "user");
        return false;
      };
      fragment.appendChild(b);
      node.appendChild(fragment);
    });

    return node;
  }

  static value(domNode: HTMLElement) {
    return domNode;
  }
}
SummaryQuestionBlot.blotName = "summary-question";
SummaryQuestionBlot.tagName = "div";
SummaryQuestionBlot.className = "summary-question";

ReactQuill.Quill.register(SummaryQuestionBlot);

class ChatBlot extends BlockEmbed {
  static create(value: string | HTMLElement) {
    let id: string;
    if (typeof value === "string") {
      id = value;
    } else if (!value.textContent) {
      id = value.dataset.id!;
    } else {
      return value;
    }
    let node: HTMLDivElement = super.create();
    node.dataset.id = id;

    fetchChatWithCache(id).then((chat) => {
      const fragment = document
        .createRange()
        .createContextualFragment(
          `<div class="highlight-card card"><div class="card-body"><div class="card-subtitle h6"><span class="question-title">Попитай ЛЕКСЕБРА</span></div><details><summary>${
            chat.query
          }<br/><div class="chat-link">[${
            chat.title
          }]</div></summary>${chat.responses[0].content.replaceAll(
            /<p><\/p>/g,
            ""
          )}</details></div></div>`
        );
      var b = document.createElement("button");
      var linkText = document.createTextNode("x");
      b.appendChild(linkText);
      b.className = "inline-link btn btn-link btn-sm";
      b.onclick = (e: any) => {
        let quill = ReactQuill.Quill.find(
          document.querySelector("#editor-collection > .ql-container")!
        );
        let blot = ReactQuill.Quill.find(e.target.parentNode);
        let index = blot.offset(quill.scroll);
        var Delta = ReactQuill.Quill.import("delta");
        quill.updateContents(new Delta().retain(index).delete(1), "user");
        return false;
      };
      fragment.appendChild(b);
      node.appendChild(fragment);
    });

    return node;
  }

  static value(domNode: HTMLElement) {
    return domNode;
  }
}
ChatBlot.blotName = "chat-title";
ChatBlot.tagName = "div";
ChatBlot.className = "chat-title";

ReactQuill.Quill.register(ChatBlot);

var Parchment = ReactQuill.Quill.import("parchment");

let config = {
  scope: Parchment.Scope.BLOCK,
};
let preset = new Parchment.Attributor.Class("preset", "preset", config);
ReactQuill.Quill.register(preset, true);

export const fetchSummaryWithCache = memoizee(async (id: string) => fetchSummary(id), {
  promise: true,
});

export const fetchChatWithCache = memoizee(async (id: string) => fetchChat(id), {
  promise: true,
});

export const fetchActWithCache = memoizee(async (id: string) => fetchJudgementAct(id), {
  promise: true,
});

const CollectionRichTextEditor: React.FC<any> = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <AddRelatedActModal
          onConfirm={(hit) => {
            let quill = ReactQuill.Quill.find(
              document.querySelector("#editor-collection > .ql-container")!
            );
            let range = quill.getSelection(true);
            let id = hit.id;
            if (hit.category === "summaries") {
              fetchSummaryWithCache(id).then((summary) => {
                quill.insertEmbed(range.index, "summary-title", id, "user");
                summary.qas.forEach((question, i) => {
                  quill.insertEmbed(
                    range.index + 1 + i,
                    "summary-question",
                    id + "#" + question.id,
                    "user"
                  );
                });
                quill.insertText(range.index + 1 + summary.qas.length, "\n", "silent");
                quill.setSelection(range.index + 1 + summary.qas.length, 0, "silent");
              });
            } else if (hit.category === "judgement-acts") {
              quill.insertEmbed(range.index, "act-title", id, "user");
              quill.insertText(range.index + 1, "\n", "silent");
              quill.setSelection(range.index + 1, 0, "silent");
            } else if (hit.category === "chats") {
              quill.insertEmbed(range.index, "chat-title", id, "user");
              quill.insertText(range.index + 1, "\n", "silent");
              quill.setSelection(range.index + 1, 0, "silent");
            }
            setShowModal(false);
          }}
          onCancel={() => setShowModal(false)}
        />
      )}
      <RichTextEditor
        {...props}
        id="collection"
        handlers={useMemo(
          () => ({
            summary: (quill: () => ReactQuill) => {
              setShowModal(true);
            },
            info: () => {},
          }),
          []
        )}
        buttons={useMemo(
          () => (
            <>
              <button className="ql-summary" title="Добави акт/резюме">
                <FaPlusCircle />
              </button>
              <select className="ql-preset">
                <option value="">Text</option>
                <option value="thesis">Thesis</option>
                <option value="notes">Notes</option>
                <option value="comments">Comments</option>
              </select>
              <OverlayTrigger
                placement="top"
                trigger="click"
                overlay={(props) => (
                  <Tooltip id="info-tooltip" {...props}>
                    Команди:
                    <ul>
                      <li>"Теза:" - добавяне на теза/извод*</li>
                      <li>
                        "Бележка:" - добавяне на работна бележка видима само в режим на редакция*
                      </li>
                      <li>"---" - добавяне на белжки под линия*</li>
                      <li>
                        "Shift+Enter" - излизане от специализиран блок и продължаване в режим
                        стандартен текст
                      </li>
                    </ul>
                    * Системата разпознава командите след натискане на клавиша "Enter"
                  </Tooltip>
                )}
              >
                <button className="ql-info" title="Помощ">
                  <FaInfoCircle />
                </button>
              </OverlayTrigger>
            </>
          ),
          []
        )}
      />
    </>
  );
};

export default CollectionRichTextEditor;
