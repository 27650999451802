import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { InlineLinkButton } from "../Button/button";

export const HelpLink: React.FC<{
  articleId: string;
}> = ({ articleId }) => {
  const { setSelectedActId } = useSelectedActContext();

  return (
    <InlineLinkButton
      onClick={async (е: any) => {
        е.preventDefault();
        е.stopPropagation();
        setSelectedActId(`article:${articleId}`);
      }}
    >
      <OverlayTrigger
        placement="top"
        overlay={(props) => (
          <Tooltip id="help-tooltip" {...props}>
            Помощна информация
          </Tooltip>
        )}
      >
        <span>
          <IoMdInformationCircleOutline
            className="text-muted"
            style={{ opacity: 0.8, marginTop: "-50%", marginLeft: "3px" }}
          />
        </span>
      </OverlayTrigger>
    </InlineLinkButton>
  );
};

export const HELP_LINK_NAV_FILTERS = "70458a9d-8af1-4755-a6ef-2efa72bc3240";
export const HELP_LINK_NAV_TOPICSEARCH = "b8724242-1a41-4112-a837-249236f2beb9";
export const HELP_LINK_TIMELINE = "62ee2e01-14c8-40a6-b319-51e657d0bb74";
export const HELP_LINK_DOCUMENTS = "cd3f7d0f-6be5-4d21-881a-68e39e108904";
export const HELP_LINK_SAVESEARCH = "5e84e91d-aa9b-4f98-a8e0-1afe231e1a38";
export const HELP_LINK_SEARCH_TAB = "79b21635-cdc3-4000-b372-f58f459685df";
export const HELP_LINK_ASK_LEXEBRA = "78d42c13-8437-44ba-8b8b-70027ddba85e";
