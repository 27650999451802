/* eslint-disable react/style-prop-object */
import { Badge } from "react-bootstrap";
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import LabelValue from "../../components/label-value";
import PreloadingLink from "../../components/PreloadingLink";
import { withAuth } from "../../contexts/auth.context";
import { Selection } from "../../hooks/useMultipleSelection";
import { Subscription } from "../../models/Subscription.model";
import SubscriptionBeneficiaryStatus from "../../models/SubscriptionBeneficiaryStatus.enum";
import SubscriptionStatus from "../../models/SubscriptionStatus.enum";
import SubscriptionTypeCode from "../../models/SubscriptionTypeCode.enum";
import {
  prefetchSubscription,
  subscriptionHref,
  useSubscriptions,
} from "../../services/subscription-services";
import { prefetchUser } from "../../services/user-services";
import ResultsTable from "./ResultsTable/results-table";
import ResultsTableHeader from "./ResultsTable/results-table-header";
import SubscriptionStatusBadge from "./subscription-status-badge";

const SubscriptionSearchResults = () => {
  const { formatMessage, formatDate } = useIntl();

  const results = useSubscriptions();

  return (
    <ResultsTable
      results={results}
      header={() => (
        <ResultsTableHeader
          addNewPath="/admin/subscriptions/new"
          sortOptions={[
            {
              value: "createdOn",
              label: formatMessage({ id: "subcription-search-page.labels.createdOn" }),
            },
            {
              value: "paidUntil",
              label: formatMessage({ id: "subcription-search-page.labels.paidUntil" }),
            },
            {
              value: "expiresOn",
              label: formatMessage({ id: "subcription-search-page.labels.expiresOn" }),
            },
          ]}
        />
      )}
      itemToSelection={itemToSelection}
      allowBulkLoad
      row={(item) => (
        <ResultsTable.ResultTableRow>
          <PreloadingLink
            to={`/admin/subscriptions/${item.id}`}
            state={{ title: getSubscriptionTitle(item) }}
            preload={(cache) => prefetchSubscription(item.id, cache)}
          >
            {item.code}
          </PreloadingLink>
          <LabelValue label={formatMessage({ id: "subcription-search-page.labels.type" })}>
            {item.plan.type.shortName}
          </LabelValue>
          <LabelValue label={formatMessage({ id: "subcription-search-page.labels.client" })}>
            {getSubscriptionTitle(item)}
          </LabelValue>
          {(item.status === SubscriptionStatus.REQUESTED ||
            item.status === SubscriptionStatus.PENDING_INITIAL_PAYMENT) && (
            <LabelValue label={formatMessage({ id: "subcription-search-page.labels.createdOn" })}>
              {formatDate(item.createdOn, {
                month: "long",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </LabelValue>
          )}
          {item.paidUntil && item.plan.periodCount > 1 && (
            <LabelValue label={formatMessage({ id: "subcription-search-page.labels.paidUntil" })}>
              {formatDate(item.paidUntil, {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </LabelValue>
          )}
          {item.status === SubscriptionStatus.ACTIVE && (
            <LabelValue
              label={formatMessage({
                id: "user-search-page.labels.current-subscription-expires-on",
              })}
            >
              {item.expiresOn ? (
                <FormattedDate value={item.expiresOn} month="long" year="numeric" day="numeric" />
              ) : (
                <FormattedMessage id="user-search-page.labels.no-expiration" />
              )}
            </LabelValue>
          )}
          {item.status === SubscriptionStatus.ACTIVE && !!item.totalAmount && (
            <LabelValue
              label={formatMessage({
                id: "subcription-search-page.labels.amount",
              })}
            >
              <FormattedNumber value={item.paidAmount} minimumFractionDigits={2} /> от{" "}
              <FormattedNumber value={item.totalAmount} style="currency" currency="BGN" />
            </LabelValue>
          )}
          <LabelValue
            label={formatMessage({
              id: "subcription-search-page.labels.beneficiaries",
            })}
          >
            {
              item.beneficiaries.filter((v) => v.status === SubscriptionBeneficiaryStatus.CONFIRMED)
                .length
            }{" "}
            от {item.licenseCount} активирани
          </LabelValue>
          {item.beneficiaries
            .filter((v) => v.email)
            .map((v) => {
              return (
                <div>
                  {v.userId ? (
                    <PreloadingLink
                      to={`/admin/users/${v.userId}`}
                      state={{ title: v.firstName + " " + v.lastName }}
                      preload={(cache) => prefetchUser(v.userId, cache)}
                    >
                      {v.email}({v.firstName} {v.lastName})
                    </PreloadingLink>
                  ) : (
                    <>
                      {v.email}({v.firstName} {v.lastName})
                    </>
                  )}
                </div>
              );
            })}
          <ResultsTable.RowIndicators item={item}>
            {item.expiresSoon && (
              <Badge bg="warning" className="me-1">
                {formatMessage({ id: `subcription-search-page.labels.expiresSoon` })}
              </Badge>
            )}
            {item.paymentDueSoon && (
              <Badge bg="warning" className="me-1">
                {formatMessage({ id: `subcription-search-page.labels.paymentDueSoon` })}
              </Badge>
            )}
            {item.plan.type.code === SubscriptionTypeCode.TRIAL && (
              <Badge bg="warning" className="me-1">
                {formatMessage({ id: `subcription-search-page.labels.promo` })}
              </Badge>
            )}
            {!item.totalAmount && (
              <Badge bg="danger" className="me-1">
                {formatMessage({ id: `subcription-search-page.labels.freeAccount` })}
              </Badge>
            )}

            <SubscriptionStatusBadge subscription={item} />
          </ResultsTable.RowIndicators>
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

const itemToSelection = (item: Subscription): Selection => ({
  _id: item.id,
  title: getSubscriptionTitle(item),
  href: subscriptionHref(item.id),
});

const getSubscriptionTitle = (item: Subscription) => {
  const customerInfo = item.subscriber.customerInfo;
  return customerInfo.companyName
    ? `${customerInfo.companyName} (${customerInfo.contactPerson})`
    : `${customerInfo.firstName} ${customerInfo.lastName}`;
};

export default withAuth(SubscriptionSearchResults);
