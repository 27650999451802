import dayjs from "dayjs";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Nav, OverlayTrigger, Tab, Table, Tooltip } from "react-bootstrap";
import {
  FaAngleLeft,
  FaAngleRight,
  FaRegClone,
  FaRegThumbsUp,
  FaSync,
  FaThumbsUp,
} from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";
import { mutate } from "../../api";
import { InlineLinkButton } from "../../components/Button/button";
import EditorInfo from "../../components/EditorInfo";
import { getInitials } from "../../components/EditorInfo/editor-info";
import { HELP_LINK_ASK_LEXEBRA } from "../../components/HelpLink/help-link";
import LabelValue from "../../components/label-value";
import { InlineLink } from "../../components/Link/link";
import LoadingIndicator from "../../components/LoadingIndicator/loadingIndicator";
import SimpleSelect from "../../components/SimpleSelect";
import Skeleton from "../../components/Skeleton";
import { all, hasPrivilege, hasUsername, Privileges, Visible } from "../../contexts/auth.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import {
  Chat,
  ChatDocuments,
  ChatInitiationContext,
  ChatResponseProgress,
  isInProgress,
  isOnError,
  isRetryable,
} from "../../models/Chat.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { JudgementAct } from "../../models/JudgementAct.model";
import Provision from "../../models/Provision.model";
import {
  mutateRecentChats,
  regenerateResponse,
  retryResponse,
  useChat,
  useChatDocuments,
} from "../../services/chat-services";
import { addUserReaction, useUserReactions } from "../../services/document-services";
import { judgementActHref } from "../../services/judgement-act-services";
import { useChatModels } from "../../services/ref-data-services";
import { summaryHref } from "../../services/summary-services";
import ResultsTableLoading from "../AdminPage/ResultsTable/results-table-loadng";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import ResponsiveFilter from "../SearchPage/FiltersSidebar/responsive-filter";
import "../SearchPage/SearchResults/search-results.scss";
import { renderTextWithProvisions } from "../SummaryViewPage/renderTextWithProvisions";
import ChatViewPageActions from "./chat-view-page-actions";
import ChatViewPageNavgation from "./chat-view-page-navigation";
import "./chat-view-page.scss";
import SearchInputLexebraGPT from "./gpt-input";
import SampleSearchCards from "./sample-search-cards";
import SubmitNegativeFeedback from "./submit-feedback";

export type ChatViewPageMode = "view" | "edit" | "landing";

const ChatViewPage = () => {
  const { chatId } = useParams();
  const chat = useChat(chatId);
  const navigate = useNavigate();

  const [mode, setMode] = useState<ChatViewPageMode>(chatId ? "view" : "landing");

  const [submitting, setSubmitting] = useState(false);

  const anyResponseInProgress = chat?.responses.findIndex((r) => isInProgress(r.progress)) !== -1;

  const { cache } = useSWRConfig();
  useEffect(() => {
    if (anyResponseInProgress) {
      const interval = setInterval(() => {
        mutate(`/chats/${chat?.id}`, (current: Chat) => current, true);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [anyResponseInProgress, chat?.id]);

  useEffect(() => {
    if (chat?.status === DocumentStatus.DRAFT) {
      mutateRecentChats(cache);
    }
  }, [cache, chat?.status]);

  useEffect(() => {
    setMode(chatId ? "view" : "landing");
  }, [chatId]);

  if (chatId && !chat) {
    return (
      <DocumentPageLayout
        mode={mode}
        className="chat-page"
        menu={chatId ? <ChatViewPageActions chat={chat} setMode={setMode} mode={mode} /> : null}
        navigation={<ChatViewPageNavgation />}
      >
        <Card className="mb-3 highlight-card">
          <Card.Body className="d-flex flex-column gap-2">
            <Skeleton count={10} />
          </Card.Body>
        </Card>
        <Tab.Container defaultActiveKey={"0"}>
          <Nav variant="pills" className="opinion-tabs">
            <Nav.Item>
              <Nav.Link eventKey="0">
                <FormattedMessage id="view-chat.labels.questions" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="1">
                <FormattedMessage id="view-chat.labels.acts" />
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="0">
              <ResultsTableLoading perRowCount={4} count={5} showHeader={false} />
            </Tab.Pane>
            <Tab.Pane eventKey="1">
              <ResultsTableLoading perRowCount={4} count={5} showHeader={false} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </DocumentPageLayout>
    );
  }

  return (
    <DocumentPageLayout
      id={chat?.id}
      title={chat?.title}
      help={HELP_LINK_ASK_LEXEBRA}
      defaultTitle="Попитай Лексебра"
      mode={mode}
      className="chat-page"
      copyrighted
      accessMode={chat?.accessMode || DocumentAccessMode.PAID}
      status={chat?.status}
      menu={chatId ? <ChatViewPageActions chat={chat} setMode={setMode} mode={mode} /> : null}
      navigation={<ChatViewPageNavgation />}
    >
      {(!chatId || mode === "edit") && (
        <>
          <SearchInputLexebraGPT
            chatId={chatId}
            query={chat?.query}
            mode={mode}
            setMode={setMode}
            submitting={submitting}
            setSubmitting={setSubmitting}
            onCreate={(newChat) => navigate(`${newChat.id}`)}
            initiationContext={ChatInitiationContext.USER_QUESTION}
          />
        </>
      )}
      {!chatId && !submitting && <SampleSearchCards />}
      {chat && !submitting && mode === "view" && <ChatViewPageForm chat={chat} />}
    </DocumentPageLayout>
  );
};

export const ChatViewPageForm: React.FC<{
  chat: Chat;
  quickView?: boolean;
}> = ({ chat, quickView = false }) => {
  const { setSelectedActId } = useSelectedActContext();

  const navigate = useNavigate();
  const { hash } = useLocation();

  const currentResponseIdx = hash ? chat?.responses.findIndex((r) => r.id === hash?.slice(1)) : 0;
  const currentResponse = chat?.responses[currentResponseIdx];

  const { data: chatModels } = useChatModels();

  const [submitting, setSubmitting] = useState(false);

  const { reactions } = useUserReactions(currentResponse?.id);
  const reaction = reactions.length ? reactions[0] : undefined;

  const chatDocuments = useChatDocuments(
    chat?.status === DocumentStatus.PUBLIC ||
      chat?.status === DocumentStatus.FOCUSED ||
      chat?.status === DocumentStatus.REVIEW
      ? chat.id
      : undefined
  );

  const intl = useIntl();

  return (
    <>
      <Card className={`mb-3 highlight-card`}>
        <Card.Body style={{ flexDirection: "column" }}>
          <p>
            <Card.Text className="fw-bold">Въпрос: </Card.Text>
            <Card.Text className="question-text">{chat.query}</Card.Text>
          </p>

          <Card.Text className="fw-bold">Отговор: </Card.Text>
          {isInProgress(currentResponse?.progress) ? (
            <>
              <LoadingIndicator response={currentResponse} />
            </>
          ) : isOnError(currentResponse?.progress) ? (
            <>
              <div
                className="response-text text-danger"
                dangerouslySetInnerHTML={{ __html: currentResponse.content }}
              />
              {isRetryable(currentResponse?.progress) && (
                <div className="response-text">
                  <p>
                    <InlineLinkButton
                      disabled={submitting}
                      onClick={() => {
                        retryResponse(chat.id, currentResponse.id)
                          .catch(() => {
                            toast.error("Възникна грешка при регенериране на отговора.");
                          })
                          .finally(() => {
                            setSubmitting(false);
                          });
                      }}
                    >
                      <FaSync /> Опитайте отново
                    </InlineLinkButton>
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="response-text">
                {currentResponse.content &&
                  parse(currentResponse.content, {
                    replace: (node) => {
                      if ((node as any).data) {
                        return RenderChatResponseWithReferences(
                          (node as any).data,
                          chatDocuments?.acts || [],
                          chatDocuments?.provisions || [],
                          setSelectedActId
                        );
                      }
                    },
                  })}
              </div>
              {currentResponse && (
                <Card.Text>
                  <span className="fw-bold">Дата на актуалност на отговора: </span>
                  {intl.formatDate(dayjs(currentResponse.createdOn, "YYYY-MM-DD").toDate(), {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </Card.Text>
              )}
              {currentResponse && (
                <Card.Text>
                  <span className="fw-bold">Зададен от: </span>
                  {chat.user.degree ? (
                    <EditorInfo editor={{ ...chat.user, username: chat.user.email }} />
                  ) : (
                    getInitials(chat.user.name)
                  )}
                </Card.Text>
              )}
              {currentResponse && reaction && (
                <>
                  <Card.Text className="fw-bold">
                    Оценка:{" "}
                    <Badge bg={reaction.type === "LIKE" ? "success" : "danger"}>
                      {reaction.type === "LIKE" ? "Добър отговор" : "Неточен отговор"}
                    </Badge>
                  </Card.Text>
                  <Card.Text>
                    <pre>{reaction?.comment}</pre>
                  </Card.Text>
                </>
              )}
            </>
          )}
          <div
            className={`d-flex gap-2 align-items-center response-action-buttons ${
              submitting ? "submitting" : ""
            }`}
          >
            {chat.responses.length > 1 && !quickView && (
              <div className="d-flex gap-1 align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip id="prev-answer" {...props}>
                      Предишен отговор
                    </Tooltip>
                  )}
                >
                  <span className="copy-button ms-0">
                    <InlineLinkButton
                      disabled={currentResponseIdx === 0}
                      onClick={() => {
                        navigate(`#${chat.responses[currentResponseIdx - 1].id}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <FaAngleLeft />
                    </InlineLinkButton>
                  </span>
                </OverlayTrigger>
                <span className="copy-button">
                  {currentResponseIdx + 1}/{chat.responses.length}
                </span>

                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip id="next-answer" {...props}>
                      Следващ отговор
                    </Tooltip>
                  )}
                >
                  <span className="copy-button ms-0">
                    <InlineLinkButton
                      disabled={currentResponseIdx >= chat.responses.length - 1}
                      onClick={() => {
                        navigate(`#${chat.responses[currentResponseIdx + 1].id}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <FaAngleRight />
                    </InlineLinkButton>
                  </span>
                </OverlayTrigger>
              </div>
            )}
            {currentResponse?.progress === ChatResponseProgress.COMPLETED && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip id="copy-answer" {...props}>
                      Копирай отговора
                    </Tooltip>
                  )}
                >
                  <span className="copy-button">
                    <InlineLinkButton
                      onClick={async () => {
                        await navigator.clipboard.writeText(currentResponse?.content || "");
                        toast.success("Отговорът беше копиран успешно.");
                      }}
                    >
                      <FaRegClone />
                    </InlineLinkButton>
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip id="good-answer" {...props}>
                      Добър отговор
                    </Tooltip>
                  )}
                >
                  <span className="copy-button ms-0">
                    {reaction?.type !== "LIKE" ? (
                      <InlineLinkButton
                        onClick={async () => {
                          try {
                            setSubmitting(true);
                            await addUserReaction(currentResponse.id, {
                              type: "LIKE",
                            });
                            toast.success("Благодарим Ви за обратната връзка.");
                          } catch (e) {
                            console.log(e);
                            toast.error("Възникна грешка при изпращане на обратната връзка.");
                          } finally {
                            setSubmitting(false);
                          }
                        }}
                      >
                        <FaRegThumbsUp />
                      </InlineLinkButton>
                    ) : (
                      <span>
                        <FaThumbsUp />
                      </span>
                    )}
                  </span>
                </OverlayTrigger>
                <SubmitNegativeFeedback
                  responseId={currentResponse.id}
                  feedbackScore={reaction?.type}
                />
                <Visible
                  when={all(hasPrivilege(Privileges.EDIT_SUMMARIES), hasUsername(chat?.user.email))}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => (
                      <Tooltip id="regenrate-answer" {...props}>
                        Регенерирай отговора
                      </Tooltip>
                    )}
                  >
                    <span className="copy-button ms-0 align-self-end">
                      <ResponsiveFilter>
                        <SimpleSelect
                          className="inline-select"
                          options={chatModels}
                          getOption={(model) => ({
                            label: model.shortName,
                            value: model.id,
                          })}
                          value={[currentResponse.model]}
                          onChange={(value) => {
                            const existingResponse = chat?.responses.find(
                              (r) => r.model.id === value[0].id
                            );
                            if (existingResponse) {
                              navigate(`#${existingResponse.id}`);
                              return;
                            }
                            setSubmitting(true);
                            regenerateResponse(chat.id, value[0])
                              .then((result) => {
                                navigate(`#${result.responses[result.responses.length - 1].id}`);
                              })
                              .catch(() => {
                                toast.error("Възникна грешка при регенериране на отговора.");
                              })
                              .finally(() => {
                                setSubmitting(false);
                              });
                          }}
                          isMulti={false}
                          valueRenderer={(value) => (
                            <span className="d-flex gap-1 mb-0 align-items-center">
                              <div>
                                <FaSync />
                              </div>
                              <div className="value-label mb-0">
                                {value.map((s) => s.label).join(", ")}
                              </div>
                            </span>
                          )}
                        />
                      </ResponsiveFilter>
                    </span>
                  </OverlayTrigger>
                </Visible>
              </>
            )}
          </div>
        </Card.Body>
      </Card>

      {(chat?.status === DocumentStatus.PUBLIC ||
        chat?.status === DocumentStatus.FOCUSED ||
        chat?.status === DocumentStatus.REVIEW) &&
        !isOnError(currentResponse?.progress) && (
          <ChatDocumentsContainer chatDocuments={chatDocuments} />
        )}
    </>
  );
};

const ChatDocumentsContainer: React.FC<{ chatDocuments?: ChatDocuments }> = ({ chatDocuments }) => {
  const { setSelectedActId } = useSelectedActContext();
  const { formatMessage } = useIntl();

  const hasQuestions = !!chatDocuments?.questions.length;

  const [activeKey, setActiveKey] = useState(hasQuestions ? "questions" : "acts");

  useEffect(() => {
    setActiveKey(hasQuestions ? "questions" : "acts");
  }, [hasQuestions]);
  return (
    <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key || "")}>
      <Nav variant="pills" className="opinion-tabs">
        {hasQuestions && (
          <Nav.Item>
            <Nav.Link eventKey="questions">
              <FormattedMessage id="view-chat.labels.questions" />
            </Nav.Link>
          </Nav.Item>
        )}
        <Nav.Item>
          <Nav.Link eventKey="acts">
            <FormattedMessage id="view-chat.labels.acts" />
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Tab.Content>
        {hasQuestions && (
          <Tab.Pane eventKey="questions">
            <div className="results-table-container">
              {chatDocuments ? (
                <Table borderless responsive size="sm" className="results-table">
                  <tbody>
                    {chatDocuments.questions.map((item) => (
                      <tr key={item.relatedQuestion.id}>
                        <td className="main-content">
                          <div className="row-content">
                            <div className="row-main-info">
                              <InlineLink
                                to={summaryHref(
                                  `${item.relatedQuestion.summary.id}#${item.relatedQuestion.id}`
                                )}
                                onClick={(e) => {
                                  if (!e.ctrlKey && !e.metaKey) {
                                    e.preventDefault();
                                    setSelectedActId("summary:" + item.relatedQuestion.summary.id);
                                  }
                                }}
                              >
                                {item.relatedQuestion.question}
                              </InlineLink>
                              <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)}>
                                <span className="text-muted ms-1">({item.score.toFixed(2)})</span>
                              </Visible>
                              <div>{item.relatedQuestion.shortTitle}</div>
                              {item.relatedQuestion.caseType &&
                                item.relatedQuestion.caseType.code !== "CRIMINAL" &&
                                item.relatedQuestion.caseType.code !== "CRIMINAL_PRIVATE" && (
                                  <LabelValue
                                    label={formatMessage({
                                      id: "view-judgement.labels.proceedingBy",
                                    })}
                                  >
                                    {item.relatedQuestion.proceeding?.shortName}
                                  </LabelValue>
                                )}
                              {item.relatedQuestion.actDate &&
                                /\d{4}\/\d{2}\/\d{2}/.test(item.relatedQuestion.actDate) && (
                                  <LabelValue
                                    label={formatMessage({ id: "view-judgement.labels.actDate" })}
                                  >
                                    {dayjs(item.relatedQuestion.actDate, "YYYY/MM/DD").format(
                                      "DD.MM.YYYY"
                                    )}{" "}
                                    г.
                                  </LabelValue>
                                )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <ResultsTableLoading perRowCount={4} count={5} showHeader={false} />
              )}
            </div>
          </Tab.Pane>
        )}
        <Tab.Pane eventKey="acts">
          <div className="results-table-container">
            {chatDocuments ? (
              <ActsTable acts={chatDocuments.acts} />
            ) : (
              <ResultsTableLoading perRowCount={4} count={5} showHeader={false} />
            )}
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

const RenderChatResponseWithReferences = (
  text: string,
  acts: { score: number; reference: string; relatedJudgementAct: JudgementAct }[],
  provisions: Provision[],
  setSelectedActId: (id: string) => void
) => {
  const references = [
    ...acts
      .filter((actItem) => actItem.reference)
      .map((actItem) => ({
        id: actItem.relatedJudgementAct.id,
        title: actItem.relatedJudgementAct.title,
        keywords: actItem.relatedJudgementAct.keywords,
        summary: actItem.relatedJudgementAct.summaries?.[0],
        reference: actItem.reference,
      })),
  ];

  let result: (string | JSX.Element)[] = [text];

  references.forEach((referenceItem) => {
    const reference = referenceItem.reference;

    const newResult: (string | JSX.Element)[] = [];
    result.forEach((token) => {
      if (typeof token !== "string") {
        newResult.push(token);
      } else {
        let remaining = token;
        while (remaining.indexOf(reference) > -1) {
          const idx = remaining.indexOf(reference);
          newResult.push(remaining.substring(0, idx));
          newResult.push(
            <>
              <Button
                variant="link"
                size="sm"
                key={`${referenceItem.id}-${idx}`}
                className="inline-link"
                style={{ display: "contents" }}
                onClick={() => setSelectedActId(referenceItem.id)}
              >
                {reference}
              </Button>
              {referenceItem.summary ? (
                <Button
                  variant="link"
                  size="sm"
                  key={`${referenceItem.summary.id}-${idx}`}
                  className="inline-link"
                  style={{ display: "contents" }}
                  onClick={() => setSelectedActId(`summary:${referenceItem.summary.id}`)}
                >
                  {` [${referenceItem.summary.shortTitle}]`}
                </Button>
              ) : referenceItem.keywords ? (
                ` [${referenceItem.keywords}] `
              ) : null}
            </>
          );
          remaining = remaining.substring(idx + reference.length);
        }
        newResult.push(remaining);
      }
    });
    result = newResult;
  });

  return renderTextWithProvisions(result, provisions);
};

const ActsTable: React.FC<{
  acts: { score: number; reference: string; relatedJudgementAct: JudgementAct }[];
}> = ({ acts }) => {
  const { setSelectedActId } = useSelectedActContext();
  const { formatMessage } = useIntl();
  return (
    <Table borderless responsive size="sm" className="results-table">
      <tbody>
        {acts
          .filter((item) => item.score > 0)
          .map((item) => (
            <tr key={item.relatedJudgementAct.id}>
              <td className="main-content">
                <div className="row-content">
                  <div className="row-main-info">
                    <InlineLink
                      to={judgementActHref(item.relatedJudgementAct.id)}
                      onClick={(e) => {
                        if (!e.ctrlKey && !e.metaKey) {
                          e.preventDefault();
                          setSelectedActId(item.relatedJudgementAct.id);
                        }
                      }}
                    >
                      {item.relatedJudgementAct.title}
                    </InlineLink>
                    <Visible when={hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)}>
                      <span className="text-muted ms-1">({item.score.toFixed(2)})</span>
                    </Visible>
                    {item.relatedJudgementAct.keywords && (
                      <div>{item.relatedJudgementAct.keywords}</div>
                    )}
                    {}
                    {item.relatedJudgementAct.caseType &&
                      item.relatedJudgementAct.caseType.code !== "CRIMINAL" &&
                      item.relatedJudgementAct.caseType.code !== "CRIMINAL_PRIVATE" && (
                        <LabelValue
                          label={formatMessage({
                            id: "view-judgement.labels.proceedingBy",
                          })}
                        >
                          {item.relatedJudgementAct.proceeding?.shortName}
                        </LabelValue>
                      )}
                  </div>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ChatViewPage;
