import { EntityModel } from "hateoas-hal-types";
import { Cache, mutate as swrMutate } from "swr";
import useRequest, {
  get,
  mutate,
  post,
  prefetch,
  put,
  useInfiniteRequest,
  useSearchRequest,
} from "../api";
import { Chat, ChatDocuments, ChatInitiationContext, ChatLegalArea } from "../models/Chat.model";
import DocumentStatus from "../models/DocumentStatus.enum";
import RefData from "../models/RefData.model";

const CHATS_BASE_URL = "/chats";
export const useChats = (
  type: "recent" | "saved" | "focused" | "last-opened" | "review",
  query?: string
) => useInfiniteRequest<Chat>(`${CHATS_BASE_URL}/${type}${query ? `?query=${query}` : ""}`);

export const useChatsAdmin = () => useSearchRequest<Chat>(CHATS_BASE_URL);

export const useChat = (id?: string) => {
  let { data } = useRequest<EntityModel<Chat>>(id ? `${CHATS_BASE_URL}/${id}` : null);
  return data;
};

export const useChatDocuments = (id?: string) => {
  let { data } = useRequest<EntityModel<ChatDocuments>>(
    id ? `${CHATS_BASE_URL}/${id}/documents` : null
  );
  return data;
};

export const prefetchChat = (id: string, cache: Cache<any>) => {
  return prefetch(`${CHATS_BASE_URL}/${id}`, cache);
};

export const fetchChat = (id: string) => {
  return get<EntityModel<Chat>>(`${CHATS_BASE_URL}/${id}`);
};

export type StartChatInput = {
  query: string;
  sourceId?: string;
  initiationContext: ChatInitiationContext;
  legalArea: ChatLegalArea;
};

export const startChat = async (data: StartChatInput) => {
  const result = (await post(`${CHATS_BASE_URL}`, data)) as EntityModel<Chat>;

  await mutate(`${CHATS_BASE_URL}/${result.id}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

export const regenerateChat = async (
  chatId: string,
  data: { query: string; sourceId?: string }
) => {
  const result = (await post(`${CHATS_BASE_URL}/${chatId}`, data)) as EntityModel<Chat>;
  await mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

export const updateChat = async (data: Chat, chatId: string, cache: Cache) => {
  const result = (await put(`${CHATS_BASE_URL}/${chatId}`, data)) as EntityModel<Chat>;
  mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  mutateRecentChats(cache);
  return result;
};

export const updateChatStatus = async (chatId: string, data: DocumentStatus, cache?: Cache) => {
  const result = (await put(`${CHATS_BASE_URL}/${chatId}/status`, data, true)) as EntityModel<Chat>;
  await mutate(
    `${CHATS_BASE_URL}/${chatId}`,
    (current: Chat) => {
      const updated = { ...current };
      updated.status = data;
      return updated;
    },
    false
  );
  mutateRecentChats(cache);
  return result;
};

export const mutateRecentChats = (cache?: any) => {
  if (cache) {
    setTimeout(async () => {
      cache.entries().forEach(([key, value]: [string, any]) => {
        if (key.indexOf("/chats/recent") !== -1) {
          swrMutate(key);
        }
      });
    }, 1000);
  }
};

export const regenerateResponse = async (chatId: string, data: RefData) => {
  const result = (await post(`${CHATS_BASE_URL}/${chatId}/responses`, data)) as EntityModel<Chat>;
  await mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

export const retryResponse = async (chatId: string, responseId: string) => {
  const result = (await post(
    `${CHATS_BASE_URL}/${chatId}/responses/${responseId}`,
    {}
  )) as EntityModel<Chat>;
  await mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

export const chatHref = (id: string): string => `${CHATS_BASE_URL}/${id}`;

// export const useResponseContents = (chatId: string, responseId: string) => {
//   return useRequest<{ data: string; status: "receiving" | "completed" }>(
//     `${CHATS_BASE_URL}/${chatId}/responses/${responseId}/content`,
//     (data) => data,
//     {
//       refreshInterval: 500,
//     }
//   );
// };

// export const useResponseContents = (chatId: string, responseId: string) => {
//   return useSSE<{ data: string; status: "receiving" | "ok" | "error" }>(
//     `${CHATS_BASE_URL}/${chatId}/responses/${responseId}/content`
//   );
// };
