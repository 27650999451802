import { EntityModel } from "hateoas-hal-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useDeviceSelectors } from "react-device-detect";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaLink,
  FaNewspaper,
  FaProductHunt,
} from "react-icons/fa";
import { MdOutlineSyncAlt } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ReactComponent as AiIcon } from "../../assets/ai.svg";
import { InlineLinkButton } from "../../components/Button/button";
import ErrorBoundary from "../../components/ErrorBoundary";
import Collapse from "../../components/SimpleCollapse";
import { hasPrivilege, Privileges, useAuth, useProtectedAction } from "../../contexts/auth.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus, { isPublishedStatus } from "../../models/DocumentStatus.enum";
import { JudgementAct, JudgementActDomain } from "../../models/JudgementAct.model";
import { JudgementActReferences } from "../../models/JudgementActReferences.model";
import { LinkedSummary } from "../../models/ReferencedDocument";
import { printSummaryShortTitle } from "../../models/Summary.model";
import {
  judgementActHref,
  useJudgementActAiReferences,
  useJudgementActReferences,
} from "../../services/judgement-act-services";
import { CollapsibleFiltersPanel } from "../SearchPage/FiltersSidebar/filters";
import { RecogitoAnno } from "./judgmenet-view-page-metadata";
import PersonalMarkersTab from "./personal-markers-tab";
import TableOfContentsTab from "./table-of-contents-tab";

export type Mode = "view" | "edit";

type JudgementPageNavigationProps = {
  act: EntityModel<JudgementAct>;
  annotations?: RecogitoAnno[];
  markers?: RecogitoAnno[];
};
const JudgementPageNavigation: React.FC<JudgementPageNavigationProps> = ({
  act,
  annotations,
  markers,
}) => {
  return (
    <>
      {annotations && annotations.length > 0 && <TableOfContentsTab annotations={annotations} />}
      <AiRelatedActsPanel act={act} />
      <BulletinPanel act={act} />
      <CaseRelatedActsPanel act={act} />
      <RelatedSummariesPanel act={act} />
      <MissingInformationPanel act={act} />
      <ResolvedReferencesPanel actId={act.id} />
      {markers && markers.length > 0 && <PersonalMarkersTab annotations={markers} />}
    </>
  );
};

const BulletinPanel: React.FC<JudgementPageNavigationProps> = ({ act }) => {
  const intl = useIntl();

  const { bulletin } = act;
  const { setSelectedActId } = useSelectedActContext();

  return bulletin ? (
    <Collapse
      icon={<FaNewspaper />}
      label={intl.formatMessage({ id: "judgement-act.page.labels.bulletin" })}
    >
      <ErrorBoundary message="Възникна грешка при зареждането на данните">
        <div className="section">
          <InlineLinkButton onClick={() => setSelectedActId(`bulletin:${bulletin.id}`)}>
            {bulletin.title}
          </InlineLinkButton>
        </div>
      </ErrorBoundary>
    </Collapse>
  ) : null;
};

const RelatedSummariesPanel: React.FC<JudgementPageNavigationProps> = ({ act }) => {
  return (
    <ErrorBoundary
      fallback={
        <Collapse icon={<FaLink />} label="Свързани резюмета" defaultExpanded>
          Възникна грешка при зареждането на данните
        </Collapse>
      }
    >
      <RelatedSummariesPanelBody act={act} />
    </ErrorBoundary>
  );
};
const RelatedSummariesPanelBody: React.FC<JudgementPageNavigationProps> = ({ act }) => {
  const references = useJudgementActReferences(act.id);
  const referencedFrom =
    references?.referencedFrom?.filter(
      (item) => isPublishedStatus(item.status) || hasPrivilege(Privileges.EDIT_SUMMARIES)
    ) || [];

  const hasItems = referencedFrom?.length > 0 || act.summaries?.length > 0;
  return (
    <Collapse icon={<FaLink />} label="Свързани резюмета" defaultExpanded={hasItems}>
      <div className="section">
        {hasItems ? (
          <>
            {referencedFrom?.length > 0 && (
              <>
                <span className="section-title">
                  <MdOutlineSyncAlt />
                  <FormattedMessage id="judgement-act.page.labels.related-to" />
                </span>

                <div className="section-content">
                  {referencedFrom.map((refSummary) => (
                    <RefSummaryLink refSummary={refSummary} />
                  ))}
                </div>
              </>
            )}
            {act.summaries?.length > 0 && (
              <>
                <span className="section-title">
                  <FaProductHunt />
                  <FormattedMessage id="judgement-act.page.labels.act-summary" />
                </span>
                <div className="section-content">
                  <ActSummaryLink act={act} />
                </div>
              </>
            )}
          </>
        ) : (
          <FormattedMessage id="judgement-act.page.labels.no-summaries" />
        )}
      </div>
    </Collapse>
  );
};

const RefSummaryLink: React.FC<{ refSummary: LinkedSummary }> = ({ refSummary }) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  const protectedAction = useProtectedAction(refSummary.accessMode === DocumentAccessMode.FREE);
  const { setSelectedActId } = useSelectedActContext();
  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id={`tooltip-${refSummary.id}`}>
          <>
            <strong>{printSummaryShortTitle(refSummary)}</strong>
            <br />
            {refSummary.shortTitle}
          </>
        </Tooltip>
      }
    >
      <span>
        <InlineLinkButton
          onClick={protectedAction(async (e) => setSelectedActId("summary:" + refSummary.id))}
          className={`${!isMobile ? "text-overflow-ellipsis" : ""} d-inline-block`}
        >
          {printSummaryShortTitle(refSummary)}
        </InlineLinkButton>
      </span>
    </OverlayTrigger>
  );
};

const ActSummaryLink: React.FC<JudgementPageNavigationProps> = ({ act }) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const summary = act.summaries[0]!;
  const protectedAction = useProtectedAction(summary.accessMode === DocumentAccessMode.FREE);
  const { setSelectedActId } = useSelectedActContext();
  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id={`tooltip-${act.summaries[0]?.id}`}>
          <>
            <strong>{act.title + " /Резюме/ "}</strong>
            <br />
            {act.summaries[0]?.shortTitle}
          </>
        </Tooltip>
      }
    >
      <span>
        <InlineLinkButton
          onClick={protectedAction(async (e) =>
            setSelectedActId("summary:" + act.summaries[0]?.id)
          )}
          className={`${!isMobile ? "text-overflow-ellipsis" : ""} d-inline-block`}
        >
          {act.title + " /Резюме/"}
        </InlineLinkButton>
      </span>
    </OverlayTrigger>
  );
};

const CaseRelatedActsPanel: React.FC<JudgementPageNavigationProps> = ({ act }) => {
  const intl = useIntl();
  const { setSelectedActId } = useSelectedActContext();

  const references = useJudgementActReferences(act.id);

  return (
    <Collapse
      icon={<FaLink />}
      label={intl.formatMessage({ id: "judgement-act.page.labels.case-acts" })}
    >
      <ErrorBoundary message="Възникна грешка при зареждането на данните">
        <div className="section">
          {references?.relatedActs
            .filter((relatedAct) => relatedAct.status !== DocumentStatus.DELETED)
            .map((relatedAct) => (
              <InlineLinkButton
                disabled={relatedAct.id === act.id}
                onClick={() => setSelectedActId(relatedAct.id)}
                title={relatedAct.title}
                key={relatedAct.id}
              >
                {relatedAct.title}
              </InlineLinkButton>
            ))}
        </div>
      </ErrorBoundary>
    </Collapse>
  );
};

const AiRelatedActsPanel: React.FC<JudgementPageNavigationProps> = ({ act }) => {
  const intl = useIntl();
  const { setSelectedActId } = useSelectedActContext();

  const references = useJudgementActAiReferences(act.id);

  const auth = useAuth();

  const accessToAskLexebra = auth.hasPrivilige(Privileges.VIEW_CHATS);

  return accessToAskLexebra &&
    references &&
    references.acts.length + references.questions.length > 0 ? (
    <Collapse
      icon={<AiIcon width="1.1rem" height="1.1rem" className="ask-lexebra-button" />}
      label={intl.formatMessage({ id: "judgement-act.page.labels.ai-references" })}
    >
      <ErrorBoundary message="Възникна грешка при зареждането на данните">
        <div className="section">
          {references?.acts
            .filter(
              (relatedAct) =>
                relatedAct.status !== DocumentStatus.DELETED && relatedAct.id !== act.id
            )
            .map((relatedAct) => (
              <>
                <InlineLinkButton
                  onClick={() => setSelectedActId(relatedAct.id)}
                  title={relatedAct.title}
                  key={relatedAct.id}
                >
                  {relatedAct.title}
                </InlineLinkButton>
                [{relatedAct.keywords}]
              </>
            ))}
        </div>
      </ErrorBoundary>
    </Collapse>
  ) : null;
};

const MissingInformationPanel: React.FC<JudgementPageNavigationProps> = ({ act }) => (
  <ErrorBoundary
    fallback={
      <MissingInformationCard>Възникна грешка при зареждането на данните</MissingInformationCard>
    }
  >
    <MissingInformationPanelBody act={act} />
  </ErrorBoundary>
);

const MissingInformationPanelBody: React.FC<JudgementPageNavigationProps> = ({ act }) => {
  const intl = useIntl();
  const isDomestic =
    act.domain === JudgementActDomain.DOMESTIC ||
    act.domain === JudgementActDomain.ADMINISTRATIVE ||
    act.domain === JudgementActDomain.CONSTITUTIONAL ||
    act.domain === JudgementActDomain.SUPREME_COURT;
  const missingInformationAct = [];
  !act.actType &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.actType" }));
  isDomestic &&
    !act.actNumber &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.actNumber" }));
  !act.actDate &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.actDate" }));
  isDomestic &&
    !act.caseType &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.caseType" }));
  !isDomestic &&
    !act.caseWord &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.caseType" }));
  isDomestic &&
    !act.caseNumber &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.caseNumber" }));
  isDomestic &&
    !act.caseYear &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.caseYear" }));
  !isDomestic &&
    !act.caseReference &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.caseReference" }));
  isDomestic &&
    !act.caseInternalId &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.caseInternalId" }));
  !act.court && missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.court" }));
  isDomestic &&
    !act.chamber &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.chamber" }));
  isDomestic &&
    !act.proceeding &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.proceeding" }));
  isDomestic &&
    !act.reporters?.length &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.reporters" }));
  !act.contents &&
    missingInformationAct.push(intl.formatMessage({ id: "edit-judgement.form.contents" }));

  return act && !isPublishedStatus(act.status) && missingInformationAct.length > 0 ? (
    <MissingInformationCard>
      <ul>
        {missingInformationAct.map((info, idx) => (
          <li key={idx}>{info}</li>
        ))}
      </ul>
    </MissingInformationCard>
  ) : null;
};

const MissingInformationCard: React.FC<{}> = ({ children }) => (
  <Card className="missing-information">
    <Card.Body>
      <Card.Subtitle>
        <FormattedMessage id="judgement-act.page.labels.missing-information" />
      </Card.Subtitle>
      {children}
    </Card.Body>
  </Card>
);

export const ResolvedReferencesPanel: React.FC<{ actId: string; showOnlyCitedFrom?: boolean }> = ({
  actId,
  showOnlyCitedFrom,
}) => {
  const intl = useIntl();
  const references = useJudgementActReferences(actId);
  const hasItems =
    !!references &&
    (showOnlyCitedFrom
      ? references.citedFrom?.length > 0
      : references.citedFrom?.length > 0 || references.citedReferences?.length > 0);
  return (
    <Collapse
      icon={<FaLink />}
      label={intl.formatMessage({ id: "judgement-act.page.labels.resolved-references" })}
      defaultExpanded={hasItems}
      key={references ? "loading" : "loaded"}
    >
      <ErrorBoundary
        fallback={
          <MissingInformationCard>
            Възникна грешка при зареждането на данните
          </MissingInformationCard>
        }
      >
        {!!references && (
          <ResolvedReferencesPanelBody
            references={references}
            showOnlyCitedFrom={showOnlyCitedFrom}
          />
        )}
      </ErrorBoundary>
    </Collapse>
  );
};

const ResolvedReferencesPanelBody: React.FC<{
  references: EntityModel<JudgementActReferences>;
  showOnlyCitedFrom?: boolean;
}> = ({ references, showOnlyCitedFrom }) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const { setSelectedActId } = useSelectedActContext();
  const citedReferences =
    references?.citedReferences.filter(
      (value, index, array) =>
        array.findIndex((ref) => ref.relatedAct.id === value.relatedAct.id) === index
    ) || [];

  return (
    <CollapsibleFiltersPanel
      collapseHeight="300px"
      openedLabel="Покажи по-малко"
      closedLabel="Покажи всичко"
    >
      {citedReferences.length > 0 || (references?.citedFrom || []).length > 0 ? (
        <>
          {!showOnlyCitedFrom && citedReferences.length > 0 && (
            <div className="section">
              <span className="section-title">
                <FaArrowCircleRight />
                <FormattedMessage
                  id="judgement-act.page.labels.references-to"
                  defaultMessage="Цитира:"
                />
              </span>
              <ul>
                {citedReferences.map((relatedAct, idx) => (
                  <li key={idx}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-${relatedAct.relatedAct.id}`}>
                          {relatedAct.relatedAct.title}
                          <div>
                            {relatedAct.relatedAct.keywords &&
                              `[${relatedAct.relatedAct.keywords}]`}
                          </div>
                        </Tooltip>
                      }
                    >
                      <Link
                        to={judgementActHref(relatedAct.relatedAct.id)}
                        onClick={(ev) => {
                          if (!ev.ctrlKey && !ev.metaKey) {
                            ev.preventDefault();
                            setSelectedActId(relatedAct.relatedAct.id);
                          }
                        }}
                        className={`link inline-link ${
                          !isMobile ? "text-overflow-ellipsis" : ""
                        } d-inline-block`}
                      >
                        {relatedAct.relatedAct.title}
                      </Link>
                    </OverlayTrigger>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {(references?.citedFrom || []).length > 0 && (
            <div className="section">
              <span className="section-title">
                <FaArrowCircleLeft />
                <FormattedMessage
                  id="judgement-act.page.labels.referenced-from"
                  defaultMessage="Цитиран от:"
                />
              </span>
              <ul>
                {(references?.citedFrom || []).map((relatedAct, idx) => (
                  <li key={idx}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-${relatedAct.relatedAct.id}`}>
                          {relatedAct.relatedAct.title}
                          <div>
                            {relatedAct.relatedAct.keywords &&
                              `[${relatedAct.relatedAct.keywords}]`}
                          </div>
                        </Tooltip>
                      }
                    >
                      <Link
                        className={`link inline-link ${
                          !isMobile ? "text-overflow-ellipsis" : ""
                        } d-inline-block`}
                        to={judgementActHref(relatedAct.relatedAct.id)}
                        onClick={(ev) => {
                          if (!ev.ctrlKey && !ev.metaKey) {
                            ev.preventDefault();
                            setSelectedActId(
                              relatedAct.relatedAct.id +
                                "#" +
                                encodeURIComponent(relatedAct.reference)
                            );
                          }
                        }}
                      >
                        {relatedAct.relatedAct.title}
                      </Link>
                    </OverlayTrigger>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <div style={{ marginLeft: "10px" }}>Няма свързани актове</div>
      )}
    </CollapsibleFiltersPanel>
  );
};

export default JudgementPageNavigation;
