import { useFormContext } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";
import { ActionButton, SecondaryButton } from "../../components/Button/button";
import { updateChat } from "../../services/chat-services";

const ChatEditPageActions = () => {
  const intl = useIntl();

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const navigate = useNavigate();

  const { cache } = useSWRConfig();

  const onSubmit =
    (shouldNavigate = true) =>
    async (data: any) => {
      try {
        const saved = await updateChat(data, data.id, cache);
        toast.success(
          intl.formatMessage(
            {
              id: "chat-edit.page.toasts.update.success",
            },
            { title: data.title }
          )
        );
        setValue("id", saved.id);
        shouldNavigate && navigate(`/ask-lexebra/${saved.id}`);
      } catch (e) {
        console.log(e);
        toast.error(
          intl.formatMessage({ id: "chat-edit.page.toasts.error" }, { title: data.title })
        );
      }
    };

  useHotkeys(
    "ctrl+s,command+s",
    (e) => {
      e.preventDefault();
      handleSubmit(onSubmit(false))();
    },
    { enableOnContentEditable: true, enableOnTags: ["INPUT", "TEXTAREA"] }
  );

  return (
    <>
      <ActionButton
        onClick={handleSubmit(onSubmit())}
        disabled={isSubmitting}
        submitting={isSubmitting}
        messageId={"button.confirm"}
      />
      <SecondaryButton
        onClick={() => navigate(-1)}
        messageId={"button.cancel"}
        disabled={isSubmitting}
      />
    </>
  );
};

export default ChatEditPageActions;
