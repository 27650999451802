import { useCallback, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { NavigateOptions } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cache, useSWRConfig } from "swr";
import { useLoadingProgress } from "../../contexts/loading-progress.context";
import { useSearchContext } from "../../contexts/search.context";
import { buildNextSearchParams, useQuery } from "../../pages/SearchPage/search-utils";
import { shortenUrl } from "../../services/url-services";

type RenderProps = {
  field: {
    onChange: (
      value: any,
      shouldNavigate?: boolean,
      count?: number
    ) => Promise<{
      search: string;
      navigate: (pathname: string, options?: NavigateOptions) => void;
    }>;
    value: any[];
    label?: string;
  };
};

type SearchFieldControllerProps = {
  name: string;
  render: (props: RenderProps) => React.ReactElement;
  preload?: (params: URLSearchParams, cache: Cache<any>) => Promise<any>;
};

const SearchFieldController: React.FC<SearchFieldControllerProps> = ({ name, render, preload }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { search } = useSearchContext();
  const query = useQuery();
  const intl = useIntl();
  const labels = useMemo(() => createFilterLabels(intl), [intl]);
  const navigate = useNavigate();
  const progress = useLoadingProgress();
  const { cache } = useSWRConfig();

  const onChange = useCallback(
    async (value: any, shouldNavigate = true, count = undefined) => {
      const searchParams = new URLSearchParams(search);
      const nextSearchParams = buildNextSearchParams(
        searchParams,
        shouldNavigate ? { [name]: value } : { ...value }
      );
      if (preload) {
        progress.start();
        try {
          await preload(nextSearchParams, cache);
        } finally {
          progress.complete();
        }
      }
      const q = nextSearchParams.toString()
        ? await shortenUrl(nextSearchParams.toString())
        : undefined;
      shouldNavigate &&
        setSearchParams(
          q
            ? {
                q,
              }
            : {},
          { replace: true }
        );
      return {
        search: `?q=${q}`,
        navigate: (pathname: string, options?: NavigateOptions) =>
          navigate({ pathname, search: `?q=${q}` }, options),
      };
    },
    [search, setSearchParams, name, navigate, preload, cache, progress]
  );
  const param = query[name] || [];
  const value = Array.isArray(param) ? param : [param];

  return <>{render({ field: { onChange, value, label: labels.get(name) } })}</>;
};

const createFilterLabels = ({ formatMessage }: IntlShape) => {
  const labelData = new Map<string, string>();
  labelData.set("judgementType", formatMessage({ id: "filters-sidebar.filters.judical-acts" }));
  labelData.set("euJudgementType", formatMessage({ id: "filters-sidebar.filters.judical-acts" }));
  labelData.set("summaryType", formatMessage({ id: "filters-sidebar.filters.summary-types" }));
  labelData.set("caseType", formatMessage({ id: "filters-sidebar.filters.case-types" }));
  labelData.set("court", formatMessage({ id: "filters-sidebar.filters.courts" }));
  labelData.set("chamber", formatMessage({ id: "filters-sidebar.filters.chambers" }));
  labelData.set("proceeding", formatMessage({ id: "filters-sidebar.filters.proceedings" }));
  labelData.set("caseStatus", formatMessage({ id: "filters-sidebar.filters.caseStatuses" }));
  labelData.set(
    "groundOfAppeal",
    formatMessage({ id: "filters-sidebar.filters.ground-of-appeals" })
  );
  labelData.set(
    "allowedByCourt",
    formatMessage({ id: "filters-sidebar.filters.allowed-by-court" })
  );
  labelData.set(
    "personalCategory",
    formatMessage({ id: "filters-sidebar.filters.personal-category" })
  );
  labelData.set("summaryTopic", formatMessage({ id: "filters-sidebar.filters.topics" }));
  labelData.set("reporter", formatMessage({ id: "filters-sidebar.filters.reporters" }));
  labelData.set("caseAdminBody", formatMessage({ id: "filters-sidebar.filters.caseAdminBody" }));
  labelData.set("caseMatter", formatMessage({ id: "filters-sidebar.filters.caseMatter" }));
  labelData.set("provision", formatMessage({ id: "filters-sidebar.filters.key-provisions" }));
  labelData.set(
    "relatedProvision",
    formatMessage({ id: "filters-sidebar.filters.related-provision" })
  );
  labelData.set(
    "summaryShortTitle",
    formatMessage({ id: "filters-sidebar.filters.summary-short-title" })
  );
  labelData.set("qaShortTitle", formatMessage({ id: "filters-sidebar.filters.qa-short-title" }));
  labelData.set("status", formatMessage({ id: "filters-sidebar.filters.statuses" }));
  labelData.set("accessMode", formatMessage({ id: "filters-sidebar.filters.accessModes" }));
  labelData.set("documentSet", formatMessage({ id: "filters-sidebar.filters.documentSets" }));
  labelData.set("editor", formatMessage({ id: "filters-sidebar.filters.editors" }));
  labelData.set("query", formatMessage({ id: "filters-sidebar.filters.query" }));
  labelData.set("actYear", formatMessage({ id: "filters-sidebar.filters.actYears" }));
  labelData.set("actMonth", formatMessage({ id: "filters-sidebar.filters.actMonths" }));
  labelData.set("actPeriod", formatMessage({ id: "filters-sidebar.filters.actPeriod" }));
  labelData.set("category", formatMessage({ id: "filters-sidebar.filters.categories" }));
  labelData.set("section", formatMessage({ id: "filters-sidebar.filters.sections" }));
  labelData.set("showInHeader", formatMessage({ id: "filters-sidebar.filters.showInHeader" }));

  labelData.set("other", formatMessage({ id: "filters-sidebar.filters.others" }));
  labelData.set("otherAct", formatMessage({ id: "filters-sidebar.filters.acts.others" }));
  labelData.set("latestActs", formatMessage({ id: "filters-sidebar.filters.latestActs" }));
  labelData.set("caseReference", formatMessage({ id: "filters-sidebar.filters.caseReference" }));

  labelData.set(
    "subscriptionPlan",
    formatMessage({ id: "filters-sidebar.filters.subscriptionPlan" })
  );
  labelData.set(
    "subscriptionStatus",
    formatMessage({ id: "filters-sidebar.filters.subscriptionStatus" })
  );
  labelData.set("channel", formatMessage({ id: "filters-sidebar.filters.channel" }));
  labelData.set("userRole", formatMessage({ id: "filters-sidebar.filters.userRole" }));
  labelData.set("userInternal", formatMessage({ id: "filters-sidebar.filters.userInternal" }));
  labelData.set("userEnabled", formatMessage({ id: "filters-sidebar.filters.userEnabled" }));
  labelData.set("proforma", formatMessage({ id: "filters-sidebar.filters.proforma" }));
  labelData.set(
    "prrExpired",
    formatMessage({ id: "filters-sidebar.filters.passwordResetRequestExpired" })
  );
  labelData.set(
    "prrStatus",
    formatMessage({ id: "filters-sidebar.filters.passwordResetRequestStatus" })
  );

  labelData.set("chatStatus", formatMessage({ id: "filters-sidebar.filters.chatStatus" }));

  return labelData;
};

export default SearchFieldController;
