import { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import { useDeviceSelectors } from "react-device-detect";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import stickybits from "stickybits";
import { HelpLink } from "../../components/HelpLink/help-link";
import Skeleton from "../../components/Skeleton";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import { useDocumentContext } from "../../contexts/document.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { DocumentStatusBadge } from "../../services/document-services";
import AccessIndicator from "../HomePage/access-indicator";
import AppPageLayout from "./app-page-layout";
import "./document-page-layout.scss";
import "./document-page-navigation.scss";

export type DocumentPageLayoutProps = {
  id?: string;
  title?: string;
  help?: any;
  defaultTitle?: string;
  status?: DocumentStatus;
  accessMode?: DocumentAccessMode;
  className?: string;
  menu?: React.ReactNode;
  navigation?: React.ReactNode;
  mode: "view" | "edit" | "landing";
  copyrighted?: boolean;
};

const DocumentPageLayout: React.FC<DocumentPageLayoutProps> = ({
  id,
  title,
  help,
  defaultTitle = "",
  status,
  accessMode,
  navigation,
  mode,
  menu,
  className = "",
  copyrighted = false,
  children,
}) => {
  const intl = useIntl();
  const isViewMode = mode === "view";
  const isLandingMode = mode === "landing";
  const tabTitle =
    title ||
    (isViewMode && !isLandingMode ? intl.formatMessage({ id: "loading.page" }) : defaultTitle);
  const { openDocument } = useDocumentContext();
  const { pathname } = useLocation();
  useEffect(() => {
    if (isViewMode && title) {
      openDocument({ title: title, href: pathname });
      if (id) {
        window.localStorage.setItem("opened-document:" + id, "#");
        setTimeout(() => window.localStorage.removeItem("opened-document:" + id), 1000);
      }
    }
  }, [openDocument, title, pathname, isViewMode, id]);

  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (headerRef.current && isMobileOnly) {
      stickybits(headerRef.current);
      // const headroom = new Headroom(headerRef.current.firstChild as any, {
      //   offset: 78,
      //   tolerance: 10,
      // });
      // headroom.init();
    }
  }, [isMobileOnly]);

  return (
    <AppPageLayout
      className={`document-page ${mode !== "edit" ? "view" : mode}-page ${className}`}
      title={tabTitle}
      navigation={navigation}
      copyrighted={copyrighted}
    >
      <Container fluid className="m-0 document-page-main-content-header-container" ref={headerRef}>
        <div className="document-page-main-content-header">
          {title || defaultTitle ? (
            <span className="h6">
              {title || defaultTitle}
              {help && <HelpLink articleId={help} />}&nbsp;
            </span>
          ) : (
            <span className="h6 w-100">
              <Skeleton count={1} />
            </span>
          )}
          {mode === "view" && accessMode && (
            <Visible when={hasPrivilege(Privileges.GENERAL_DOCUMENTS_STATUS)}>
              <span className="badge document-access-indicator-badge">
                <AccessIndicator showAlways mode={accessMode} placement="top" />
              </span>
            </Visible>
          )}
          <DocumentStatusBadge status={status} />
          <div className="document-page-main-content-header-actions">{menu}</div>
        </div>
      </Container>

      <Container fluid className="m-0 document-page-main-content-body">
        {children}
      </Container>
    </AppPageLayout>
  );
};

export default DocumentPageLayout;
