import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import parse, { domToReact } from "html-react-parser";
import { useEffect, useRef } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";
import CarouselPreview from "../../components/Carousel/carousel-preview";
import EditorCollectionContextProvider from "../../contexts/editor-collection.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { EditorCollection } from "../../models/EditorCollection.model";
import { useEditorCollection } from "../../services/editor-collection-services";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import { scrollToTargetAdjusted } from "../JudgementPage/judgmenet-view-page-metadata";
import EditorCollectionPageNavigation from "./editor-collection-page-navigation";
import EditorCollectionPageActions from "./editor-collection-view-page-actions";
import EditorCollectionViewPageLoading from "./editor-collection-view-page-loading";
import "./editor-collection-view-page.scss";
interface EditorCollectionViewPageProps {
  collection: EntityModel<EditorCollection>;
}

const EditorCollectionViewPage = () => {
  const { collectionId } = useParams();
  const collection = useEditorCollection(collectionId);

  return collection ? (
    <EditorCollectionViewForm collection={collection} />
  ) : (
    <EditorCollectionViewPageLoading mode="view" className="page-loading" />
  );
};

const EditorCollectionViewForm: React.FC<EditorCollectionViewPageProps> = ({ collection }) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const { setSelectedActId } = useSelectedActContext();
  useEffect(() => {
    ref.current?.querySelectorAll(".act-title a").forEach((link) => {
      (link as HTMLAnchorElement).onclick = () => {
        setSelectedActId((link.parentElement?.parentElement as HTMLDivElement).dataset.id);
        return false;
      };
    });
  }, [setSelectedActId]);

  useEffect(() => {
    ref.current?.querySelectorAll(".summary-title a").forEach((link) => {
      (link as HTMLAnchorElement).onclick = () => {
        setSelectedActId(
          "summary:" + (link.parentElement?.parentElement as HTMLDivElement).dataset.id
        );
        return false;
      };
    });
  }, [setSelectedActId]);

  useEffect(() => {
    ref.current?.querySelectorAll(".chat-title .chat-link").forEach((link) => {
      (link as HTMLDivElement).onclick = (e) => {
        setSelectedActId("chat:id:" + (link.closest("div[data-id]") as HTMLDivElement).dataset.id);
        e.preventDefault();
        e.stopPropagation();
        return false;
      };
    });
  }, [setSelectedActId]);

  return (
    <EditorCollectionContextProvider>
      <DocumentPageLayout
        id={collection.id}
        title={collection.title}
        status={collection.status}
        mode="view"
        accessMode={collection.accessMode}
        className="editor-collection-page"
        menu={<EditorCollectionPageActions collection={collection} />}
        navigation={<EditorCollectionPageNavigation />}
      >
        <CarouselPreview
          imageSrc={collection.imageData}
          title={collection.title}
          subTitle={collection.subTitle}
        />

        {!!collection.publishedBy && (
          <div className="text-muted published-by">
            <span className="author">Съставител: {collection.publishedBy}</span>
            <span className="date">
              <FormattedMessage
                id="view-editor-collection.publish-info"
                values={{
                  publishedBy: collection.publishedBy,
                  publishedOn: intl.formatDate(
                    dayjs(collection.publishDate, "YYYY-MM-DD").toDate(),
                    {
                      month: "long",
                      day: "2-digit",
                      year: "numeric",
                    }
                  ),
                }}
              />
            </span>
          </div>
        )}
        <div className="act-text" ref={ref}>
          {parse(
            collection.contents.replaceAll(
              /(<h2.*?)(?=(<h2)|$)/gs,
              "<div class='main-section'>$1</div>"
            ),
            {
              replace,
            }
          )}
        </div>
      </DocumentPageLayout>
    </EditorCollectionContextProvider>
  );
};

const replace = (node: any) => {
  // strip internal comments
  if ((node as any).attribs?.["class"] === "preset-comments") {
    return <></>;
  }
  // join thesis <p> and convert them to <details>
  if ((node as any).attribs?.["class"] === "preset-thesis") {
    if ((node as any).next?.attribs?.["class"] === "preset-thesis") {
      return <></>;
    } else {
      const nodes = [];
      let curr = node as any;
      while (curr?.attribs?.["class"] === "preset-thesis") {
        if (curr?.prev?.attribs?.["class"] !== "preset-thesis") {
          nodes.push(domToReact(curr.children, { replace }));
        } else {
          nodes.push(<p>{domToReact(curr.children, { replace })}</p>);
        }

        curr = curr.prev;
      }
      nodes.reverse();
      nodes[0] = <summary id={"_" + uuid()}>{nodes[0]}</summary>;
      return (
        <details className="preset-thesis" open>
          {nodes}
        </details>
      );
    }
  }
  // show icon tooltips
  if ((node as any).attribs?.["data-title"]) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={(props) => <Tooltip {...props}>{(node as any).attribs["data-title"]}</Tooltip>}
      >
        <span>{domToReact((node as any).children, { replace })}</span>
      </OverlayTrigger>
    );
  }
  // render not references [X]
  if ((node as any).data) {
    return renderTextWithNoteReference((node as any).data);
  }
};

const renderTextWithNoteReference = (text: string) => {
  let result: any[] = [text];

  const newResult: any[] = [];
  result.forEach((token: string) => {
    if (typeof token !== "string") {
      newResult.push(token);
    } else {
      let remaining = token;
      let provision_regex = new RegExp(/\[\d+\]/);
      let remaining_provision_match = remaining.match(provision_regex);
      while (remaining_provision_match) {
        const idx = remaining_provision_match.index ? remaining_provision_match.index : 0;
        const match = remaining_provision_match[0];
        newResult.push(remaining.substr(0, idx));
        newResult.push(
          <Button
            variant="link"
            size="sm"
            className="inline-link"
            style={{ display: "contents", fontSize: "80%" }}
            onClick={(е: any) => {
              const el =
                document.getElementsByClassName("preset-notes")[
                  parseInt(match.substring(1, match.length - 1)) - 1
                ];
              el && scrollToTargetAdjusted(el as HTMLElement);
            }}
          >
            {match}
          </Button>
        );
        remaining = remaining.substr(idx + match.length);
        remaining_provision_match = remaining.match(provision_regex);
      }
      newResult.push(remaining);
    }
  });
  return <>{newResult}</>;
};

export default EditorCollectionViewPage;
