import dayjs from "dayjs";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { config } from "../constants";
import { logout, refresh } from "../services/auth.service";

export type LoggedUser = {
  validUntil: string;
  username: string;
  firstName: string;
  initials: string;
  lastName: string;
  runAs: boolean;
  privileges: string[];
  token: string;
};

export type AuthContextType = {
  currentUser?: LoggedUser;
  setCurrentUser: (user?: LoggedUser) => void;
  refreshCurrentUser: (user?: LoggedUser) => void;
  hasPrivilige: (privilige: Privileges) => boolean;
  setShowLoginModal: (
    show: boolean,
    options?: {
      successCallback?: () => any;
      cancelable?: boolean;
    }
  ) => void;
  showLoginModal: boolean;
  setShowSubscriptionModal: (show: boolean) => void;
  showSubscriptionModal: boolean;
  hasSuccessCallback: boolean;
  isLoginCancelable: boolean;
  isLoginSessionActive: () => boolean;
};

export const AuthContext = createContext<AuthContextType>({
  setCurrentUser: async () => {},
  refreshCurrentUser: async () => {},
  hasPrivilige: () => false,
  showLoginModal: false,
  setShowLoginModal: () => {},
  showSubscriptionModal: false,
  setShowSubscriptionModal: () => {},
  hasSuccessCallback: false,
  isLoginCancelable: false,
  isLoginSessionActive: () => false,
});

export const AuthContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const user = localStorage.getItem("user");
  const [currentUser, setCurrentUserInState] = useState<LoggedUser | undefined>(
    user ? JSON.parse(user) : null
  );
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState<boolean>(false);
  const [successCallback, setSuccessCallback] = useState<() => any>();
  const [cancelable, setCancelable] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser) {
      const stillLogged = isLogged()(currentUser);
      if (stillLogged) {
        localStorage.setItem("user", JSON.stringify(currentUser));
      } else {
        setCurrentUserInState(undefined);
      }
      setShowLoginModal(!stillLogged);
    } else {
      localStorage.removeItem("user");
      //setShowLoginModal(true);
    }
  }, [setCurrentUserInState, setShowLoginModal, currentUser]);

  const noCurrentUser = currentUser === undefined;

  useEffect(() => {
    noCurrentUser && logout();
  }, [noCurrentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser: setCurrentUserInState,
        refreshCurrentUser: useCallback(() => {
          !noCurrentUser &&
            refresh().then((newUser) =>
              setCurrentUserInState((currentUser) => {
                return JSON.stringify(currentUser) === JSON.stringify(newUser)
                  ? currentUser
                  : newUser;
              })
            );
        }, [setCurrentUserInState, noCurrentUser]),
        hasPrivilige: (privilege: Privileges) =>
          currentUser ? currentUser.privileges.includes(privilege) : false,
        showLoginModal,
        setShowLoginModal: useCallback(
          (show, { successCallback: newSuccessCallback, cancelable } = {}) => {
            setShowLoginModal(show);
            if (!show) {
              if (newSuccessCallback) {
                newSuccessCallback();
              } else {
                successCallback && successCallback();
              }
              setSuccessCallback(undefined);
              setCancelable(false);
            } else {
              setCancelable(!!cancelable);
              setSuccessCallback(() => newSuccessCallback);
            }
          },
          [successCallback, setShowLoginModal, setSuccessCallback, setCancelable]
        ),
        hasSuccessCallback: !!successCallback,
        isLoginCancelable: cancelable,
        isLoginSessionActive: useCallback(() => isLogged()(currentUser), [currentUser]),
        showSubscriptionModal,
        setShowSubscriptionModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const getToken = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user).token : null;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ConnectUser = ({
  children,
}: {
  children: (authCtx: AuthContextType) => JSX.Element;
}) => {
  const ctx = useContext(AuthContext);

  return children({ ...ctx });
};

export type VisibleProps = {
  when: (user?: LoggedUser) => boolean;
  fallback?: () => React.ReactElement;
};

export const Visible: React.FC<VisibleProps> = ({ when, children, fallback = () => null }) =>
  when(useAuth().currentUser) ? <>{children}</> : fallback();

export const hasUsername = (username: string) => (u?: LoggedUser) =>
  u ? u.username === username : false;
export const hasName = (name: string) => (u?: LoggedUser) =>
  u ? u.firstName + " " + u.lastName === name : false;
export const hasPrivilege = (privilege: Privileges) => (u?: LoggedUser) =>
  u ? u.privileges.includes(privilege) : false;
export const isLogged = () => (u?: LoggedUser) => !!u && dayjs(u.validUntil).isAfter(new Date());

export const oneOf =
  (...conds: ((u?: LoggedUser) => boolean)[]) =>
  (u?: LoggedUser) =>
    conds.some((c) => c(u));
export const all =
  (...conds: ((u?: LoggedUser) => boolean)[]) =>
  (u?: LoggedUser) =>
    conds.every((c) => c(u));
export const not = (cond: (u?: LoggedUser) => boolean) => (u?: LoggedUser) => !cond(u);

export enum Privileges {
  EDIT_ALL_SUMMARIES = "edit.all.summaries",
  PROTECT_SUMMARIES = "protect.summaries",
  EDIT_SUMMARIES = "edit.summaries",
  EDIT_PROVISIONS = "edit.provisions",
  EDIT_TOPICS = "edit.topics",
  EDIT_ARTICLES = "edit.news-articles",
  EDIT_USERS = "edit.users",
  VIEW_COLLECTIONS = "view.editor-collections",
  EDIT_COLLECTIONS = "edit.editor-collections",
  EDIT_BULLETINS = "edit.bulletins",
  VIEW_OPENED_DOC_HISTORY = "view.opened-documents-history",
  SEARCH_EXTENDED_FILTERS = "search.extended-filters",
  GENERAL_DOCUMENTS_STATUS = "general.document.status",
  VIEW_DOCUMENTS = "view.documents",
  VIEW_ALL_DOCUMENT_SETS = "view.document-set.ALL",
  EDIT_ALL_EDITOR_COLLECTIONS = "edit.all.editor-collections",
  VIEW_CHATS = "view.chats",
}

export const useProtectedAction = (bypass = false) => {
  const { setShowLoginModal, isLoginSessionActive, hasPrivilige, setShowSubscriptionModal } =
    useAuth();
  return (action: (e?: any) => any) => (e?: any) => {
    if (bypass) {
      action(e);
      return;
    }
    if (isLoginSessionActive()) {
      if (hasPrivilige(Privileges.VIEW_DOCUMENTS)) {
        action(e);
      } else {
        e.preventDefault();
        setShowSubscriptionModal(true);
      }
    } else {
      e.preventDefault && e.preventDefault();
      setShowLoginModal(true, { successCallback: () => action(e), cancelable: true });
    }
  };
};

export const ProtectedLink = (props: any) => {
  const { setShowLoginModal, isLoginSessionActive, hasPrivilige, setShowSubscriptionModal } =
    useAuth();
  const navigate = useNavigate();
  const { component, ...linkProps } = props;
  return (
    <Link
      {...linkProps}
      onClick={(e: any) => {
        if (!isLoginSessionActive()) {
          e.preventDefault();
          const href = e.target.href as string;
          setShowLoginModal(true, {
            successCallback: () =>
              navigate(href.substring(href.indexOf(config.url!) + config.url!.length)),
            cancelable: true,
          });
        } else if (!hasPrivilige(Privileges.VIEW_DOCUMENTS)) {
          e.preventDefault();
          setShowSubscriptionModal(true);
        }
        props.onClick && props.onClick(e);
      }}
    />
  );
};

export const ProtectedLinkOnlyLogin = (props: any) => {
  const { setShowLoginModal, isLoginSessionActive } = useAuth();
  const navigate = useNavigate();
  const { component, ...linkProps } = props;
  return (
    <Link
      {...linkProps}
      onClick={(e: any) => {
        if (!isLoginSessionActive()) {
          e.preventDefault();
          const href = e.target.href as string;
          setShowLoginModal(true, {
            successCallback: () =>
              navigate(href.substring(href.indexOf(config.url!) + config.url!.length)),
            cancelable: true,
          });
        }
        props.onClick && props.onClick(e);
      }}
    />
  );
};

export const withActiveSubscrption = (BaseComponent: any, FallbackComponent?: any) =>
  withAuth((props: any) => {
    const { hasPrivilige, isLoginSessionActive, setShowSubscriptionModal } = useAuth();
    useEffect(() => {
      if (!hasPrivilige(Privileges.VIEW_DOCUMENTS) && isLoginSessionActive()) {
        setShowSubscriptionModal(true);
      }
    }, [setShowSubscriptionModal, isLoginSessionActive, hasPrivilige]);
    return FallbackComponent &&
      !hasPrivilige(Privileges.VIEW_DOCUMENTS) &&
      isLoginSessionActive() ? (
      <FallbackComponent {...props} />
    ) : (
      <BaseComponent {...props} />
    );
  }, FallbackComponent);

export const withAuth = (BaseComponent: any, FallbackComponent?: any) => (props: any) => {
  const { setShowLoginModal, showLoginModal, isLoginSessionActive } = useAuth();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const navigateToCurrent = useCallback(
    () => navigate(pathname + search),
    [pathname, search, navigate]
  );
  useEffect(() => {
    if (!isLoginSessionActive()) {
      setShowLoginModal(true, { successCallback: navigateToCurrent });
    }
  }, [navigateToCurrent, setShowLoginModal, showLoginModal, isLoginSessionActive]);
  return FallbackComponent && !isLoginSessionActive() ? (
    <FallbackComponent {...props} />
  ) : (
    <BaseComponent {...props} />
  );
};

export const withPublicAccess = (BaseComponent: any) => (props: any) => {
  const { setShowLoginModal, isLoginCancelable } = useAuth();
  const navigateToNone = useCallback(() => {}, []);
  useEffect(() => {
    !isLoginCancelable && setShowLoginModal(false, { successCallback: navigateToNone });
  }, [setShowLoginModal, isLoginCancelable, navigateToNone]);
  return <BaseComponent {...props} />;
};

export default AuthContextProvider;
