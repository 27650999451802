import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Nav, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import { FaEdit, FaStar } from "react-icons/fa";
import { RiAwardFill, RiFocus3Fill } from "react-icons/ri";
import { FormattedDate } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import img_questions from "../../assets/images/thumbnails/questions.png";
import { PrimaryButton } from "../../components/Button/button";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import { useChats } from "../../services/chat-services";
import "./sample-search-cards.scss";

const SampleSearchCards = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const defaultTab = "focused";
  const [activeKey, setActiveKey] = useState(location.hash?.slice(1) || defaultTab);

  // Update state when hash changes
  useEffect(() => {
    const hash = location.hash.slice(1);
    if (hash) {
      setActiveKey(hash);
    }
  }, [location.hash]);

  // Change URL hash when user changes tab
  const handleSelect = (k: string | null) => {
    if (k && k !== activeKey) {
      navigate(`#${k}`);
      setActiveKey(k);
    }
  };
  return (
    <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
      <Nav variant="pills" className="opinion-tabs no-swipe">
        <Nav.Item>
          <Nav.Link eventKey="focused">
            <RiFocus3Fill />
            Избрано от редактор
          </Nav.Link>
        </Nav.Item>

        {/* <Nav.Item>
          <Nav.Link eventKey="0">
            <FaHistory /> Последно отваряни
          </Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link eventKey="saved">
            <FaStar />
            Запазени
          </Nav.Link>
        </Nav.Item>
        <Visible when={hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)}>
          <Nav.Item>
            <Nav.Link eventKey="review">
              <RiAwardFill />
              За преглед
            </Nav.Link>
          </Nav.Item>
        </Visible>
      </Nav>

      <Tab.Content>
        {/* <Tab.Pane eventKey="0">
          <TabPaneContents chats={opened} />
        </Tab.Pane> */}

        <Tab.Pane eventKey="saved">
          <TabPaneContents category={"saved"} dateField="createdOn" />
        </Tab.Pane>
        <Tab.Pane eventKey="focused">
          <TabPaneContents category={"focused"} dateField="publishDate" />
        </Tab.Pane>
        <Visible when={hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)}>
          <Tab.Pane eventKey="review">
            <TabPaneContents category={"review"} dateField="createdOn" />
          </Tab.Pane>
        </Visible>
      </Tab.Content>
    </Tab.Container>
  );
};

const TabPaneContents: React.FC<{
  category: "recent" | "saved" | "focused" | "last-opened" | "review";
  dateField: "createdOn" | "publishDate";
}> = ({ category, dateField }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: chats, totalCount, size, setSize, hasMore, isLoadingMore } = useChats(category);
  return (
    <>
      {chats && totalCount === 0 && (
        <div className="text-muted text-center mt-5">Няма намерени резултати</div>
      )}
      <div className="sample-search-cards">
        {chats
          ?.flatMap((chat) => chat._embedded.items)
          .map((chat) => (
            <div
              key={chat.id}
              className="search-card"
              onClick={() =>
                navigate(chat.id, {
                  state: {
                    from: location.pathname + (location.hash ? location.hash : ""),
                  },
                })
              }
            >
              <div className="d-flex flex-column align-items-center">
                <img src={img_questions} alt="Questions Thumbnail" className="card-thumbnail" />
                <span className="fw-bold pt-3 fs-5 text-muted">
                  <FormattedDate value={chat[dateField]} day="numeric" />
                </span>
                <span className=" fs-6 text-muted">{dayjs(chat[dateField]).format("MMM")}</span>
                <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)}>
                  {chat.manuallyEdited && (
                    <span className="ms-1">
                      <OverlayTrigger
                        placement="top"
                        overlay={(props) => (
                          <Tooltip id="correct-tooltip" {...props}>
                            С редакторска корекция
                          </Tooltip>
                        )}
                      >
                        <span data-title="С редакторска корекция">
                          <FaEdit />
                        </span>
                      </OverlayTrigger>
                    </span>
                  )}
                </Visible>
              </div>
              <span>
                <span className="card-title">{chat.title}</span>
                <div className="text-muted preview">{chat.query}</div>
              </span>
            </div>
          ))}
      </div>
      {hasMore && (
        <PrimaryButton
          submitting={isLoadingMore}
          messageId="button.load-more"
          onClick={() => setSize(size + 1)}
        />
      )}
    </>
  );
};

export default SampleSearchCards;
