import { EntityModel } from "hateoas-hal-types";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ReactDragListView from "react-drag-listview";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import AutoSizedTextArea from "../../components/AutoSizeTextArea";
import NotAuthorized from "../../components/NotAuthorized";
import RichTextEditor from "../../components/RichTextEditor";
import RefDataSelectSingle from "../../components/SimpleSelect/ref-data-select-single";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { ConnectUser, hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import { Chat } from "../../models/Chat.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { useChat } from "../../services/chat-services";
import { useChatModels } from "../../services/ref-data-services";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import ChatEditPageActions from "./chat-edit-page-actions";
import "./chat-edit-page.scss";

interface ChatEditPageProps {
  chat?: EntityModel<Chat>;
}

const ChatEditPage = () => {
  const { chatId } = useParams();
  const chat = useChat(chatId);

  return chat || !chatId ? (
    <ChatEditForm chat={chat} />
  ) : (
    <></> // <NewsArticleViewPageLoading mode="edit" className="page-loading" />
  );
};

const ChatEditForm: React.FC<ChatEditPageProps> = ({ chat }) => {
  const serverData = chat;
  const intl = useIntl();

  const { data: chatModels } = useChatModels();

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: serverData,
  });

  const { control } = methods;

  const { fields, remove, move } = useFieldArray<EntityModel<Chat>, "responses", "fieldId">({
    name: "responses",
    keyName: "fieldId",
    control: control,
  });

  const [selection, setSelection] = useState("0");

  const dragProps = {
    onDragEnd: (from: number, to: number) => {
      const realTo = Math.min(fields.length - 1, to);
      if (to > -1) {
        move(from, realTo);
        setSelection(realTo + "");
      }
    },
    nodeSelector: "li",
    ignoreSelector: "li:last-of-type",
    handleSelector: "button",
  };

  return (
    <FormProvider {...methods}>
      <DocumentPageLayout
        title={chat?.title}
        defaultTitle={chat?.title}
        mode="edit"
        className="chat-page"
        menu={<ChatEditPageActions />}
      >
        <Form className="edit-form" autoComplete="off">
          <Row>
            <Col md="9">
              <Form.Group controlId="title">
                <Form.Label>
                  <FormattedMessage id="edit-chat.form.title" />
                </Form.Label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="status">
                <Form.Label>
                  <FormattedMessage id="edit-chat.form.status" />
                </Form.Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={Object.keys(DocumentStatus)}
                      getOption={(status) => ({
                        label: intl.formatMessage({ id: `document.status.${status}` }),
                        value: status,
                      })}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="9">
              <Form.Group controlId="query">
                <Form.Label>
                  <FormattedMessage id="edit-chat.form.query" />
                </Form.Label>
                <Controller
                  name="query"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="accessMode">
                <Form.Label>
                  <FormattedMessage id="edit-chat.form.access-mode" />
                </Form.Label>
                <Controller
                  name="accessMode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ConnectUser>
                      {({ currentUser }) => (
                        <SelectSingle
                          options={Object.keys(DocumentAccessMode)}
                          getOption={(accessMode) => ({
                            label: intl.formatMessage({ id: `document.access-mode.${accessMode}` }),
                            value: accessMode,
                          })}
                          value={value}
                          onChange={onChange}
                          isDisabled={!hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)(currentUser)}
                        />
                      )}
                    </ConnectUser>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ReactDragListView.DragColumn {...dragProps}>
                <div className="question-tabs">
                  <Tabs
                    activeKey={selection}
                    onSelect={(key) => {
                      setSelection(key!);
                    }}
                  >
                    {fields.map((qa, i) => (
                      <Tab
                        key={qa.fieldId}
                        tabClassName="response-tab"
                        eventKey={i + ""}
                        title={
                          <span>
                            <span className="tab-title-text">
                              {fields.length > 1 ? `Отговор ${i + 1}` : "Отговор"}
                            </span>
                            {fields.length > 1 && i + "" === selection && (
                              <FaTimes
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => {
                                  remove(i);
                                  setSelection((i === fields.length - 1 ? i - 1 : i) + "");
                                  e.stopPropagation();
                                }}
                                title={intl.formatMessage({ id: "button.remove" })}
                              />
                            )}
                          </span>
                        }
                      >
                        <Row>
                          <Col md="3">
                            <Form.Group controlId={`responses.${i}.model`}>
                              <Form.Label>
                                <FormattedMessage id="edit-chat.form.model" />
                              </Form.Label>
                              <Controller
                                name={`responses.${i}.model` as const}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <RefDataSelectSingle
                                    onChange={onChange}
                                    value={value}
                                    isDisabled
                                    options={chatModels}
                                  />
                                )}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={9}>
                            <Form.Group controlId={`responses.${i}.comments`}>
                              <Form.Label>
                                <FormattedMessage id="edit-chat.form.comments" />
                              </Form.Label>
                              <Controller
                                name={`responses.${i}.comments` as const}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <AutoSizedTextArea
                                    onChange={onChange}
                                    value={value}
                                    minHeight={100}
                                  />
                                )}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId={`responses.${i}.content`}>
                              <Form.Label>Отговор</Form.Label>

                              <Controller
                                name={`responses.${i}.content` as const}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <RichTextEditor
                                    id={`responses-${i}-content`}
                                    defaultValue={value}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Tab>
                    ))}
                  </Tabs>
                </div>
              </ReactDragListView.DragColumn>
            </Col>
          </Row>
        </Form>
      </DocumentPageLayout>
    </FormProvider>
  );
};

const ProtectedChatEditPage = () => {
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)} fallback={NotAuthorized}>
      <ChatEditPage />
    </Visible>
  );
};

export default ProtectedChatEditPage;
