/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useDeviceSelectors } from "react-device-detect";
import { ErrorBoundary } from "react-error-boundary";
import { useHotkeys } from "react-hotkeys-hook";
import "react-sortable-tree/style.css";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { InlineLinkButton } from "./components/Button/button";
import { ForbiddenError, UnauthorizedError } from "./components/GlobalErrorBoundary";
import { useAuth } from "./contexts/auth.context";
import ErrorModal from "./pages/error.modal";
import Header from "./pages/Header/header";
import LoginModal from "./pages/login.modal";
import NoSubscriptionModal from "./pages/no-subscription.modal";
import RegistrationModal from "./pages/registration.modal";
import PasswordResetModal from "./pages/reset-password.modal";
import JudgementActQuickView from "./pages/SummaryPage/judgement-act-quick-view";

const ErrorFallbackComponent = ({ error }) => {
  return error instanceof UnauthorizedError ? (
    <LoginModal />
  ) : error instanceof ForbiddenError ? (
    <div>Forbidden</div>
  ) : (
    <ErrorModal />
  );
};

const App = ({ children }) => {
  const { showLoginModal, currentUser, showSubscriptionModal, refreshCurrentUser } = useAuth();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  useHotkeys(
    "ctrl+y,command+y",
    (e) => {
      return false;
    },
    { enableOnContentEditable: true, enableOnTags: ["INPUT", "TEXTAREA"] }
  );
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  useEffect(() => {
    refreshCurrentUser();
  }, [refreshCurrentUser]);

  return (
    <Container className={`router ${isMobileOnly ? "mobile-view" : ""}`} fluid>
      {showLoginModal && (
        <LoginModal
          suspend={showPasswordResetModal || showRegistrationModal}
          links={
            <>
              <InlineLinkButton onClick={() => setShowRegistrationModal(true)}>
                Регистрация
              </InlineLinkButton>
              |
              <InlineLinkButton
                onClick={() => setShowPasswordResetModal(true)}
                style={{ marginRight: "auto" }}
              >
                Забравена парола
              </InlineLinkButton>
            </>
          }
        />
      )}
      {showSubscriptionModal && <NoSubscriptionModal />}
      {showRegistrationModal && (
        <RegistrationModal onClose={() => setShowRegistrationModal(false)} />
      )}
      {showPasswordResetModal && (
        <PasswordResetModal onClose={() => setShowPasswordResetModal(false)} />
      )}
      <ErrorBoundary
        FallbackComponent={ErrorFallbackComponent}
        resetKeys={[currentUser?.validUntil]}
      >
        <Header />
        {children}
      </ErrorBoundary>
      <JudgementActQuickView />

      <ToastContainer
        position={isMobileOnly ? "top-right" : "bottom-right"}
        autoClose={isMobileOnly ? 3000 : 6000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        transition={Slide}
      />
    </Container>
  );
};

export default App;
