import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { FaHistory, FaRulerHorizontal } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { InlineLinkButton, PrimaryButton } from "../../components/Button/button";
import Collapse from "../../components/Collapse";
import { Chat } from "../../models/Chat.model";
import { useChats } from "../../services/chat-services";
import { useCurrentUserQuotas } from "../../services/user-services";

const splitChatsByDate = (chats?: Chat[]) => {
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");

  const fromToday: Chat[] = [];
  const fromYesterday: Chat[] = [];
  const fromLastTenDays: Chat[] = [];

  chats?.forEach((chat) => {
    const chatDate = dayjs(chat.createdOn);

    if (chatDate.isSame(today, "day")) {
      fromToday.push(chat);
    } else if (chatDate.isSame(yesterday, "day")) {
      fromYesterday.push(chat);
    } else {
      fromLastTenDays.push(chat);
    }
  });

  return { fromToday, fromYesterday, fromLastTenDays };
};

const ChatViewPageNavgation = () => {
  const [searchInput, setSearchInput] = useState("");
  const [debouncedValue, setDebouncedValue] = React.useState("");

  useDebounce(
    () => {
      setDebouncedValue(searchInput);
    },
    1000,
    [searchInput]
  );
  const {
    data: chats,
    hasMore,
    size,
    setSize,
    isLoadingMore,
    totalCount,
  } = useChats("recent", debouncedValue);

  const { fromToday, fromYesterday, fromLastTenDays } = useMemo(
    () => splitChatsByDate(chats?.flatMap((chat) => chat._embedded.items)),
    [chats]
  );

  const filteredSearchHistoryToday = fromToday.filter((item) =>
    item.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredSearchHistoryYesterday = fromYesterday.filter((item) =>
    item.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredSearchHistoryLast10Days = fromLastTenDays.filter((item) =>
    item.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const navigate = useNavigate();
  const location = useLocation();

  const onClick = (chat: Chat) => async () => {
    navigate(`/ask-lexebra/${chat.id}`, {
      state: {
        from: location.pathname + (location.hash ? location.hash : ""),
      },
    });
  };

  const getProgressBarClass = (available: number, limit: number) => {
    if (available === 0) {
      return "danger";
    } else if (available / limit <= 0.2) {
      return "warning";
    } else {
      return "success";
    }
  };

  const userQuotas = useCurrentUserQuotas();
  return (
    <Container
      fluid
      className="filters-sidebar"
      style={{
        boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
      }}
    >
      {userQuotas && userQuotas.availableGptCredits <= 5 && (
        <Collapse icon={<FaRulerHorizontal />} label="Квота">
          <div className="d-flex flex-column">
            <div className="mb-1 text-muted">
              Остават ви {userQuotas.availableGptCredits} въпроса за седмицата
            </div>
          </div>
        </Collapse>
      )}
      <Collapse icon={<FaHistory />} label="Задавани въпроси">
        <div className="d-flex justify-content-center">
          <PrimaryButton
            messageId=""
            disabled={userQuotas && userQuotas.availableGptCredits <= 0}
            onClick={() => navigate("/ask-lexebra")}
            submitting={false}
          >
            Нов въпрос
          </PrimaryButton>
        </div>
        <Form.Control
          type="text"
          placeholder="Търсене..."
          className="mt-2 mb-2"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        {chats && totalCount === 0 && (
          <div className="text-center mt-3 text-muted">Не бяха открити резултати</div>
        )}
        {filteredSearchHistoryToday.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Днес
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryToday.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <InlineLinkButton onClick={onClick(searchItem)}>
                    {searchItem.title}
                  </InlineLinkButton>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}

        {filteredSearchHistoryYesterday.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Вчера
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryYesterday.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <InlineLinkButton onClick={onClick(searchItem)}>
                    {searchItem.title}
                  </InlineLinkButton>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}

        {filteredSearchHistoryLast10Days.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Последните 10 дни
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryLast10Days.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <InlineLinkButton onClick={onClick(searchItem)}>
                    {searchItem.title}
                  </InlineLinkButton>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}
        {hasMore && (
          <PrimaryButton
            submitting={isLoadingMore}
            messageId="button.load-more"
            onClick={() => setSize(size + 1)}
          />
        )}
      </Collapse>
    </Container>
  );
};

export default ChatViewPageNavgation;
